/*
formBuilder - http://kevinchappell.github.io/formBuilder/
Version: 1.10.1
Author: Kevin Chappell <kevin.b.chappell@gmail.com>
*/
'use strict';
/**
* PHP htmlentities recreation in JavaScript
* minor changes to match styleguide and remove unneeded functionality
* @see https://github.com/kvz/phpjs
*/
jQuery(document).ready(function ($) {
  var fbTemplate = document.getElementById('fb-template-new');
  var renderedContainer = document.getElementById('rendered-form');

  $(fbTemplate).bind("change", function (e) {
    $(fbTemplate).formRender({
      container: renderedContainer
    });
  });

});
function valida(e) {
  key = e.keyCode || e.which;
  tecla = String.fromCharCode(key).toLowerCase();
  letras = "1234567890abcdefghijklmnñopqrstuvwxyz";
  var especiales = [8];

  var tecla_especial = false
  for (var i in especiales) {
    if (key == especiales[i]) {
      tecla_especial = true;
      break;
    }
  }

  if (letras.indexOf(tecla) == -1 && !tecla_especial)
    return false;
}
var Base64 = function () {


  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode = function (input) {
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      input = Base64._utf8_encode(input);

      while (i < input.length) {

        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

      }

      return output;
    },


    decode = function (input) {
      var output = "";
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;

      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

      while (i < input.length) {

        enc1 = this._keyStr.indexOf(input.charAt(i++));
        enc2 = this._keyStr.indexOf(input.charAt(i++));
        enc3 = this._keyStr.indexOf(input.charAt(i++));
        enc4 = this._keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }
      output = Base64._utf8_decode(output);
      return output;

    },

    _utf8_encode = function (string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {

        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        }
        else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }

      }

      return utftext;
    },

    _utf8_decode = function (utftext) {
      var string = "";
      var i = 0;
      var c = c1 = c2 = 0;

      while (i < utftext.length) {

        c = utftext.charCodeAt(i);

        if (c < 128) {
          string += String.fromCharCode(c);
          i++;
        }
        else if ((c > 191) && (c < 224)) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        }
        else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
          i += 3;
        }

      }
      return string;
    }
}



var HTML_ENTITIES = function () {
  'use strict';

  var htmlEntities = {};
  var _helpers = null;


  htmlEntities.getHtmlTranslationTable = function (table, quoteStyle) {

    var entities = {},
      hashMap = {},
      decimal;
    var constMappingTable = {},
      constMappingQuoteStyle = {};
    var useTable = {},
      useQuoteStyle = {};

    // Translate arguments
    constMappingTable[0] = 'HTML_SPECIALCHARS';
    constMappingTable[1] = 'HTML_ENTITIES';
    constMappingQuoteStyle[0] = 'ENT_NOQUOTES';
    constMappingQuoteStyle[2] = 'ENT_COMPAT';
    constMappingQuoteStyle[3] = 'ENT_QUOTES';

    useTable = !isNaN(table) ? constMappingTable[table] : table ? table.toUpperCase() : 'HTML_SPECIALCHARS';
    useQuoteStyle = !isNaN(quoteStyle) ? constMappingQuoteStyle[quoteStyle] : quoteStyle ? quoteStyle.toUpperCase() : 'ENT_COMPAT';

    if (useTable !== 'HTML_SPECIALCHARS' && useTable !== 'HTML_ENTITIES') {
      throw new Error('Table: ' + useTable + ' not supported');
      // return false;
    }


    if (useTable === 'HTML_ENTITIES') {
      entities['34'] = '&quot;';
      entities['160'] = '&nbsp;';
      entities['161'] = '&iexcl;';
      entities['162'] = '&cent;';
      entities['163'] = '&pound;';
      entities['164'] = '&curren;';
      entities['165'] = '&yen;';
      entities['166'] = '&brvbar;';
      entities['167'] = '&sect;';
      entities['168'] = '&uml;';
      entities['169'] = '&copy;';
      entities['170'] = '&ordf;';
      entities['171'] = '&laquo;';
      entities['172'] = '&not;';
      entities['173'] = '&shy;';
      entities['174'] = '&reg;';
      entities['175'] = '&macr;';
      entities['176'] = '&deg;';
      entities['177'] = '&plusmn;';
      entities['178'] = '&sup2;';
      entities['179'] = '&sup3;';
      entities['180'] = '&acute;';
      entities['181'] = '&micro;';
      entities['182'] = '&para;';
      entities['183'] = '&middot;';
      entities['184'] = '&cedil;';
      entities['185'] = '&sup1;';
      entities['186'] = '&ordm;';
      entities['187'] = '&raquo;';
      entities['188'] = '&frac14;';
      entities['189'] = '&frac12;';
      entities['190'] = '&frac34;';
      entities['191'] = '&iquest;';
      entities['192'] = '&Agrave;';
      entities['193'] = '&Aacute;';
      entities['194'] = '&Acirc;';
      entities['195'] = '&Atilde;';
      entities['196'] = '&Auml;';
      entities['197'] = '&Aring;';
      entities['198'] = '&AElig;';
      entities['199'] = '&Ccedil;';
      entities['200'] = '&Egrave;';
      entities['201'] = '&Eacute;';
      entities['202'] = '&Ecirc;';
      entities['203'] = '&Euml;';
      entities['204'] = '&Igrave;';
      entities['205'] = '&Iacute;';
      entities['206'] = '&Icirc;';
      entities['207'] = '&Iuml;';
      entities['208'] = '&ETH;';
      entities['209'] = '&Ntilde;';
      entities['210'] = '&Ograve;';
      entities['211'] = '&Oacute;';
      entities['212'] = '&Ocirc;';
      entities['213'] = '&Otilde;';
      entities['214'] = '&Ouml;';
      entities['215'] = '&times;';
      entities['216'] = '&Oslash;';
      entities['217'] = '&Ugrave;';
      entities['218'] = '&Uacute;';
      entities['219'] = '&Ucirc;';
      entities['220'] = '&Uuml;';
      entities['221'] = '&Yacute;';
      entities['222'] = '&THORN;';
      entities['223'] = '&szlig;';
      entities['224'] = '&agrave;';
      entities['225'] = '&aacute;';
      entities['226'] = '&acirc;';
      entities['227'] = '&atilde;';
      entities['228'] = '&auml;';
      entities['229'] = '&aring;';
      entities['230'] = '&aelig;';
      entities['231'] = '&ccedil;';
      entities['232'] = '&egrave;';
      entities['233'] = '&eacute;';
      entities['234'] = '&ecirc;';
      entities['235'] = '&euml;';
      entities['236'] = '&igrave;';
      entities['237'] = '&iacute;';
      entities['238'] = '&icirc;';
      entities['239'] = '&iuml;';
      entities['240'] = '&eth;';
      entities['241'] = '&ntilde;';
      entities['242'] = '&ograve;';
      entities['243'] = '&oacute;';
      entities['244'] = '&ocirc;';
      entities['245'] = '&otilde;';
      entities['246'] = '&ouml;';
      entities['247'] = '&divide;';
      entities['248'] = '&oslash;';
      entities['249'] = '&ugrave;';
      entities['250'] = '&uacute;';
      entities['251'] = '&ucirc;';
      entities['252'] = '&uuml;';
      entities['253'] = '&yacute;';
      entities['254'] = '&thorn;';
      entities['255'] = '&yuml;';
      entities['39'] = '&rsquo;';
    }

    if (useQuoteStyle !== 'ENT_NOQUOTES') {
      entities['34'] = '&quot;';
    }

    entities['60'] = '&lt;';
    entities['62'] = '&gt;';

    // ascii decimals to real symbols
    for (decimal in entities) {
      if (entities.hasOwnProperty(decimal)) {
        hashMap[String.fromCharCode(decimal)] = entities[decimal];
      }
    }

    return hashMap;
  };

  htmlEntities.encode = function (string, quoteStyle) {
    var hashMap = this.getHtmlTranslationTable('HTML_ENTITIES', quoteStyle);

    string = string === null ? '' : string + '';

    if (!hashMap) {
      return false;
    }

    if (quoteStyle && quoteStyle === 'ENT_QUOTES') {
      hashMap['\''] = '&#039;';
    }

    var regex = new RegExp('&(?:#\\d+|#x[\\da-f]+|[a-zA-Z][\\da-z]*);|[' + Object.keys(hashMap).join('')
      // replace regexp special chars
      .replace(/([()[\]{}\-.*+?^$|\/\\])/g, '\\$1') + ']', 'g');

    return string.replace(regex, function (ent) {
      var encoded = void 0;
      if (ent.length > 1) {
        encoded = ent;
      }
      encoded = hashMap[ent];
      return encoded;
    });
  };

  return htmlEntities;
}(HTML_ENTITIES || {});
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

function formBuilderHelpersFn(opts, formBuilder) {
  'use strict';

  var _helpers = {
    doCancel: false
  };

  formBuilder.events = formBuilderEventsFn();

  /**
  * Convert an attrs object into a string
  *
  * @param  {Object} attrs object of attributes for markup
  * @return {string}
  */
  _helpers.attrString = function (attrs) {
    var attributes = [];
    for (var attr in attrs) {
      if (attrs.hasOwnProperty(attr)) {
        attr = _helpers.safeAttr(attr, attrs[attr]);
        attributes.push(attr.name + attr.value);
      }
    }
    var attrString = attributes.join(' ');
    return attrString;
  };

  /**
  * Convert camelCase into lowercase-hyphen
  *
  * @param  {string} str
  * @return {string}
  */
  _helpers.hyphenCase = function (str) {
    str = str.replace(/([A-Z])/g, function ($1) {
      return '-' + $1.toLowerCase();
    });

    return str.replace(/\s/g, '-').replace(/^-+/g, '');
  };

  /**
  * Convert converts messy `cl#ssNames` into valid `class-names`
  *
  * @param  {string} str
  * @return {string}
  */
  _helpers.makeClassName = function (str) {
    str = str.replace(/[^\w\s\-]/gi, '');
    return _helpers.hyphenCase(str);
  };

  _helpers.safeAttrName = function (name) {
    var safeAttr = {
      className: 'class'
    };

    return safeAttr[name] || _helpers.hyphenCase(name);
  };

  _helpers.safeAttr = function (name, value) {
    name = _helpers.safeAttrName(name);

    var valString = window.JSON.stringify(HTML_ENTITIES.encode(value));

    value = value ? '=' + valString : '';
    return {
      name: name,
      value: value
    };
  };

  /**
  * Add a mobile class
  *
  * @return {string}
  */
  _helpers.mobileClass = function () {
    var mobileClass = '';
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
        mobileClass = ' fb-mobile';
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return mobileClass;
  };

  /**
  * Callback for when a drag begins
  *
  * @param  {Object} event
  * @param  {Object} ui
  */
  _helpers.startMoving = function (event, ui) {
    event = event;
    ui.item.show().addClass('moving');
    _helpers.startIndex = $('li', this).index(ui.item);
  };

  /**
  * Callback for when a drag ends
  *
  * @param  {Object} event
  * @param  {Object} ui
  */
  _helpers.stopMoving = function (event, ui) {
    event = event;
    ui.item.removeClass('moving');
    if (_helpers.doCancel) {
      $(ui.sender).sortable('cancel');
      $(this).sortable('cancel');
    }
    _helpers.save();
    _helpers.doCancel = false;
  };

  /**
  * jQuery UI sortable beforeStop callback used for both lists.
  * Logic for canceling the sort or drop.
  */
  _helpers.beforeStop = function (event, ui) {
    event = event;

    var form = document.getElementById(opts.formID),
      lastIndex = form.children.length - 1,
      cancelArray = [];
    _helpers.stopIndex = ui.placeholder.index() - 1;

    if (!opts.sortableControls && ui.item.parent().hasClass('frmb-control')) {
      cancelArray.push(true);
    }

    if (opts.prepend) {
      cancelArray.push(_helpers.stopIndex === 0);
    }

    if (opts.append) {
      cancelArray.push(_helpers.stopIndex + 1 === lastIndex);
    }

    _helpers.doCancel = cancelArray.some(function (elem) {
      return elem === true;
    });
  };

  /**
  * Make strings safe to be used as classes
  *
  * @param  {string} str string to be converted
  * @return {string}     converter string
  */
  _helpers.safename = function (str) {
    return str.replace(/\s/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase();
  };

  /**
  * Strips non-numbers from a number only input
  *
  * @param  {string} str string with possible number
  * @return {string}     string without numbers
  */
  _helpers.forceNumber = function (str) {
    return str.replace(/[^0-9]/g, '');
  };

  /**
  * hide and show mouse tracking tooltips, only used for disabled
  * fields in the editor.
  *
  * @todo   remove or refactor to make better use
  * @param  {Object} tt jQuery option with nexted tooltip
  * @return {void}
  */
  _helpers.initTooltip = function (tt) {
    var tooltip = tt.find('.tooltip');
    tt.mouseenter(function () {
      if (tooltip.outerWidth() > 200) {
        tooltip.addClass('max-width');
      }
      tooltip.css('left', tt.width() + 14);
      tooltip.stop(true, true).fadeIn('fast');
    }).mouseleave(function () {
      tt.find('.tooltip').stop(true, true).fadeOut('fast');
    });
    tooltip.hide();
  };

  /**
  * Attempts to get element type and subtype
  *
  * @param  {Object} $field
  * @return {Object}
  */
  _helpers.getTypes = function ($field) {
    return {
      type: $field.attr('type'),
      subtype: $('.fld-subtype', $field).val()
    };
  };
  /*Datos que no van a datos abiertos*/
  _helpers.getDatosAbiertos = function ($field) {
    var datosAbiertos = $('.fld-datosAbiertos', $field).is(':checked')
    return {
      datosAbiertos: datosAbiertos
    };
  };
  /*VA por el tipo de regex */
  _helpers.getRegex = function ($field) {
    return {
      regex_type: $('.fld-regex', $field).val()
    };
  };
  _helpers.getElementType = function ($field) {
    return {
      //tipo de elemento
      elementType: $('.fld-elementType', $field).val()
    };
  };
  _helpers.getNumberStepper = function ($field) {
    return {
      numberStepper: $('.fld-numberStepper', $field).val()
    };
  };
  /*_helpers.getNumberGroup = function ($field) {
    return {
      numberGroup: $('.fld-numberGroup', $field).val()
    };
  };*/
  _helpers.getUrlButton = function ($field) {

    return {
      urlButton: $('.fld-urlButton', $field).val()
    };
  }; _helpers.getRedirect = function ($field) {

    return {
      redirect: $('.fld-redirect', $field).val()
    };
  };

  _helpers.getStartdate = function ($field) {
    var startDate = $('.fld-startDate', $field).val();
    var retorno = startDate;
    return {
      startdate: retorno
    };
  };

  _helpers.getSelectLabel = function ($field) {
    return {
      etiqueta2: $('.fld-selectLabel', $field).val()
    };
  };
  _helpers.validarFormatoFecha = function (campo) {
    if (campo !== undefined) {
      var pieces = campo.split('-');
      pieces.reverse();
      var reversed = pieces.join('/');
      var RegExPattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;
      if ((reversed.match(RegExPattern)) && (reversed != '')) {
        return true;
      } else {
        return false;
      }
    }
  }; _helpers.fechaValida = function (campo) {
    if (campo !== undefined) {
      var fechaf = campo.split("-");
      var day = fechaf[2];
      var month = fechaf[1];
      var year = fechaf[0];
      var date = new Date(year, month, '0');
      if ((day - 0) > (date.getDate() - 0)) {
        return false;
      }
      return true;
    }
  };
  _helpers.base64encode = function (input) {
    var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = _helpers.base64_utf8_encode(input);

    while (i < input.length) {

      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);

    }

    return output;

  };
  _helpers.base64_utf8_encode = function (string) {

    string = string.replace(/\r\n/g, "\n");
    var utftext = "";

    for (var n = 0; n < string.length; n++) {

      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      }
      else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }

    }

    return utftext;


  };

  _helpers.validarFechaMenorAFinal = function (datei, datef) {
    var dateInicial = new Date(datei);
    var dateFinal = new Date(datef);

    if (dateFinal >= dateInicial)
      return false;
    else
      return true;
  };
  _helpers.existeFecha = function (fecha) {
    var fechaf = fecha.split("/");
    var day = fechaf[0];
    var month = fechaf[1];
    var year = fechaf[2];
    var date = new Date(year, month, '0');
    if ((day - 0) > (date.getDate() - 0)) {
      return false;
    }
    return true;
  };
  _helpers.getFinaldate = function ($field) {
    var returnval = "";
    var startDate = _helpers.getStartdate($field);
    var finalDate = $('.fld-finalDate', $field).val();
    if (startDate !== "undefined" && startDate !== '' && finalDate !== "undefined" && finalDate !== '') {

      if (parseInt(startDate.startdate) <= parseInt(finalDate)) {
        returnval = finalDate;
      }
      else {
        _helpers.dialog2('La fecha inicial ' + startDate.startdate + ' es mayor a la final.');
        returnval = finalDate;
      }
    }
    return {
      finaldate: returnval
    };
  };
  _helpers.getnSeleccionados = function ($field) {
    return {

      nseleccionados: $('.fld-nseleccionados', $field).val()
    };
  };
  _helpers.getImportancia = function ($field) {
    return {
      importancia: $('.fld-importancia', $field).val()
    };
  }
  _helpers.getUrlWs = function (url) {
    var response_display;
    var values_wslist = "";
    values_wslist = url;
    var res = '';
    if (values_wslist !== undefined && values_wslist !== null) {
      res = values_wslist.split("|");
      response_display = "nombre";
    }

    return {
      ws_name: res[0],
      ws_jerarquia: res[1],
      ws_id: res[2],
      ws_list: res[3],
      ws_response_value: res[4],
      ws_response_display: response_display
    };
  };
  _helpers.getWslist = function ($field) {
    var response_display;
    var values_wslist = "";

    values_wslist = $('.fld-wslist', $field).val();
    var res = '';
    if (values_wslist !== undefined && values_wslist !== null) {
      res = values_wslist.split("|");
      response_display = "nombre";
    }

    return {
      ws_name: res[0],
      ws_jerarquia: res[1],
      ws_id: res[2],
      ws_list: res[3],
      ws_response_value: res[4],
      ws_response_display: response_display
    };
  };
  _helpers.getWsUrlResult = function ($field) {
    var values_wsUrlList = "";
    values_wsUrlList = $('.fld-wsUrlResult', $field).val();
    var res = '';
    if (values_wsUrlList !== undefined && values_wsUrlList !== null) {
      res = values_wsUrlList.split("|");
    }
    return {
      ws_jerarquia: res[0],

      ws_id: res[1],
      /* Url de envio*/
      wsUrl: res[2]
    };
  };
  _helpers.getResponseDisplay = function ($field) {
    return {
      responseDisplay: $('.fld-responseDisplay', $field).val()
    };
  };
  _helpers.getResponseValue = function ($field) {
    return {
      responseValue: $('.fld-responseValue', $field).val()
    };
  };
  _helpers.getTextoApoyo = function ($field) {
    return {
      textoapoyo: $('.fld-textoapoyo', $field).val()
    };
  };
  _helpers.getRegexParse = function (value) {
    var regex_msg = "";
    var myRegex_64 = "";
    if (value === "Sin Validación") {

      myRegex_64 = "";


    } else if (value === "Alfanumérico") {

      // var myRegex_64 = "XlvDocOpw63Ds8O6w4HDicONw5PDmsOxw5EuLDstXyQlJiBhLXpBLVowLTldKiQ=";
      myRegex_64 = "XlvDvMOcw6HDqcOtw7PDusOBw4nDjcOTw5rDscORLiw7LV8kJSYgYS16QS1aMC05XSok";
      regex_msg = "Campo inválido, sólo permite números y letras.";
    }
    else if (value === "CURP") {
      myRegex_64 = "XltBLVp8YS16XXs0fShbMC05XXs2fSkoSHxNfGh8bSlbQS1afGEtel17NX0oW0EtWnxhLXp8MC05XXsxfSlbMC05XXsxfSQ=";
      regex_msg = "Campo inválido, sólo permite agregar los 16 dígitos de la CURP.";
    }
    else if (value === "Correo electrónico") {
      myRegex_64 = "XihbYS16QS1aMC05X1wuXC1dKStcQCgoW2EtekEtWjAtOVwtXSkrXC4pKyhbYS16QS1aMC05XXsyLDR9KSsk";
      regex_msg = "Campo inválido, sólo permite agregar correo electrónico.";
    }
    else if (value === "RFC") {
      myRegex_64 = "XihbQS1aLGEteizDkSwmXXszLDR9KFswLTldezJ9KSgwWzEtOV18MVswLTJdKSgwWzEtOV18MVswLTldfDJbMC05XXwzWzAtMV0pW0EtWnxhLXpcZF17M30pJA==";
      regex_msg = "Campo inválido, sólo permite agregar los 13 caracteres del RFC.";
    }
    else if (value === "Sólo números") {
      myRegex_64 = "XlswLTldKyQ=";
      regex_msg = "Campo inválido, sólo permite números.";
    }
    else if (value === "Sólo letras") {
      myRegex_64 = "XlthLXpBLVrDscORw6HDqcOtw7PDusOBw4nDjcOTw5rDvMOcIF0qJA==";
      regex_msg = "Campo inválido, sólo permite letras.";
    }
    else if (value === "Código postal") {
      myRegex_64 = "XlswLTldezUsNX0k";
      regex_msg = "Campo inválido, sólo permite agregar los 5 números del código postal.";
    }
    return {
      regex_64: myRegex_64,
      regex_msg: regex_msg
    };
  };

  // Remove null or undefined values
  _helpers.trimAttrs = function (attrs) {
    var xmlRemove = [null, undefined, '', false];
    for (var i in attrs) {
      if (_helpers.inArray(attrs[i], xmlRemove)) {
        delete attrs[i];
      }
    }
    return attrs;
  };

  // Remove null or undefined values
  _helpers.escapeAttrs = function (attrs) {
    for (var attr in attrs) {
      if (attrs.hasOwnProperty(attr)) {
        attrs[attr] = HTML_ENTITIES.encode(attrs[attr]);
      }
    }

    return attrs;
  };
  // Remove null or undefined values
  _helpers.escapeAlphanumerics = function (attrs) {
    var re = /^[a-zA-Z0-9]+$/;
    var str = "";
    if ((attrs !== null && attrs.trim() === '') || !re.test(attrs)) {
      // alert('No se permiten caracteres especiales en el usuario.');
      str = attrs;
      str = str.replace(/[^a-zA-Z 0-9.]+/g, '');
    } else {
      str = attrs;
    }
    return str;
  };

  /**
  * XML save
  *
  * @param  {Object} form sortableFields node
  */
  _helpers.xmlSave = function (form) {
    var formDataNew = $(form).toXML(_helpers);
    if (window.JSON.stringify(formDataNew) === window.JSON.stringify(formBuilder.formData)) {
      return false;
    }
    formBuilder.formData = formDataNew;
  };

  _helpers.jsonSave = function () {
    opts.notify.warning('json data not available yet');
  };

  /**
  * Saves and returns formData
  * @return {XML|JSON}
  */
  _helpers.save = function () {
    var element = _helpers.getElement(),
      form = document.getElementById(opts.formID),
      formData;

    var doSave = {
      xml: _helpers.xmlSave,
      json: _helpers.jsonSave
    };

    // save action for current `dataType`
    formData = doSave[opts.dataType](form);

    if (element) {
      element.value = formBuilder.formData;
      if (window.jQuery) {
        $(element).trigger('change');
      } else {
        element.onchange();
      }
    }

    //trigger formSaved event
    document.dispatchEvent(formBuilder.events.formSaved);
    return formData;
  };

  /**
  * Attempts to find an element,
  * useful if formBuilder was called without Query
  * @return {Object}
  */
  _helpers.getElement = function () {
    var element = false;
    if (formBuilder.element) {
      element = formBuilder.element;

      if (!element.id) {
        _helpers.makeId(element);
      }

      if (!element.onchange) {
        element.onchange = function () {
          opts.notify.success(opts.messages.formUpdated);
        };
      }
    }

    return element;
  };
  /**
  * increments the field ids with support for multiple editors
  * @param  {String} id field ID
  * @return {String}    incremented field ID
  */
  _helpers.incrementId = function (id) {
    var split = id.lastIndexOf('-'),
      newFieldNumber = parseInt(id.substring(split + 1)) + 1,
      baseString = id.substring(0, split);
    return baseString + '-' + newFieldNumber;
  };

  _helpers.makeId = function () {
    var element = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

    var epoch = new Date().getTime();

    return element.tagName + '-' + epoch;
  };

  /**
  * Collect field attribute values and call fieldPreview to generate preview
  * @param  {Object} field jQuery wrapped dom object @todo, remove jQuery dependency
  */
  _helpers.updatePreview = function (field) {
    var fieldData = field.data('fieldData') || {};
    var fieldClass = field.attr('class');
    if (fieldClass.indexOf('ui-sortable-handle') !== -1) {
      return;
    }

    /*Formateo de label  tipo parrafo*/
    var typesString = $(field).attr('type');

    //var parrafo = $('.fld-elementType', field).val()
    var label = "";
    //
    var contenido = $('.fld-label', field).val();
    if (contenido !== undefined) {
      var labelSplitSubrayado = contenido.split('__');

      //*****
      if (labelSplitSubrayado.length >= 2) {

        var conta = 1;
        labelSplitSubrayado.forEach(function (element, i) {

          if (i === conta) {
            label = label + "<u>" + labelSplitSubrayado[i] + "</u>";
            conta = conta + 2;
          } else {
            label = label + labelSplitSubrayado[i];
          }
        });
      }
      else {
        label = contenido;
      }
      var labelSplitNegritas = label.split('**');
      //***
      if (labelSplitNegritas.length >= 2) {
        var conta = 1;
        label = "";
        labelSplitNegritas.forEach(function (element, i) {

          if (i === conta) {
            label = label + "<b>" + labelSplitNegritas[i] + "</b>";
            conta = conta + 2;
          } else {
            label = label + labelSplitNegritas[i];
          }
        });
      }

      //***

      var labelSplitCursivas = label.split('~~');
      //***
      if (labelSplitCursivas.length >= 2) {
        var conta = 1;
        label = "";
        labelSplitCursivas.forEach(function (element, i) {

          if (i === conta) {
            label = label + "<i>" + labelSplitCursivas[i] + "</i>";
            conta = conta + 2;
          } else {
            label = label + labelSplitCursivas[i];
          }
        });
      }

      var labelSplitTachado = label.split('--');
      //*** Tachado
      if (labelSplitTachado.length >= 2) {
        var conta = 1;
        label = "";
        labelSplitTachado.forEach(function (element, i) {

          if (i === conta) {
            label = label + "<s>" + labelSplitTachado[i] + "</s>";
            conta = conta + 2;
          } else {
            label = label + labelSplitTachado[i];
          }
        });
      }
    }
    else {
      label = $('.fld-label', field).val()
    }  //***




    var fieldType = $(field).attr('type'),
      $prevHolder = $('.prev-holder', field),
      previewData = {

        label: label,
        type: fieldType
      },
      preview;

    var subtype = $('.fld-subtype', field).val();
    if (subtype) {
      previewData.subtype = subtype;
    }

    var maxlength = $('.fld-maxlength', field).val();
    if (maxlength) {

      previewData.maxlength = maxlength;
    }
    else {
      previewData.maxlength = "1";
    }

    previewData.className = $('.fld-className', field).val() || fieldData.className || '';

    var placeholder = $('.fld-placeholder', field).val();
    if (placeholder) {
      previewData.placeholder = placeholder;
    }

    var style = $('.btn-style', field).val();
    if (style) {
      previewData.style = style;
    }

    if (fieldType === 'checkbox') {
      previewData.toggle = $('.checkbox-toggle', field).is(':checked');
    }
    if (fieldType.match(/(checkbox-group|radio-group)/)) {
      previewData.enableOther = $('[name="enable-other"]', field).is(':checked');
    }
    if (fieldType.match(/(select|selectws|selectWSnested|selectnested|checkbox-group|radio-group)/)) {
      previewData.values = [];
      previewData.multiple = $('[name="multiple"]', field).is(':checked');

      $('.sortable-options li', field).each(function () {
        var option = {};
        option.selected = $('.option-selected', this).is(':checked');
        option.value = $('.option-value', this).val();
        option.label = $('.option-label', this).val();
        previewData.values.push(option);
      });
    }

    previewData.className = _helpers.classNames(field, previewData);
    $('.fld-className', field).val(previewData.className);

    field.data('fieldData', previewData);
    preview = _helpers.fieldPreview(previewData);

    $prevHolder.html(preview);

    $('input[toggle]', $prevHolder).kcToggle();
  };

  /**
  * Generate preview markup
  *
  * @todo   make this smarter and use tags
  * @param  {Object} attrs
  * @return {String}       preview markup for field
  */
  _helpers.fieldPreview = function (attrs) {
    var i,
      preview = '',
      epoch = new Date().getTime();
    attrs = jQuery.extend({}, attrs);
    attrs.type = attrs.subtype || attrs.type;
    var toggle = attrs.toggle ? 'toggle' : '';
    // attrs = _helpers.escapeAttrs(attrs);
    var attrsString = _helpers.attrString(attrs);

    switch (attrs.type) {
      case 'textarea':
      case 'rich-text':
        preview = '<textarea ' + attrsString + '></textarea>';
        break;
      case 'button':
      case 'submit':
        preview = '<button ' + attrsString + '>' + attrs.label + '</button>';
        break;
      case 'select':
        var options = '',
          multiple = attrs.multiple ? 'multiple' : '';
        attrs.values.reverse();
        if (attrs.placeholder) {
          options += '<option disabled selected>' + attrs.placeholder + '</option>';
        }
        for (i = attrs.values.length - 1; i >= 0; i--) {
          var selected = attrs.values[i].selected && !attrs.placeholder ? 'selected' : '';
          options += '<option value="' + attrs.values[i].value + '" ' + selected + '>' + attrs.values[i].label + '</option>';
        }
        preview = '<' + attrs.type + ' class="' + attrs.className + '" ' + multiple + '>' + options + '</' + attrs.type + '>';
        break;
      case 'selectws':
        var options = '',
          type = attrs.type.replace('ws', ''),
          multiple = attrs.multiple ? 'multiple' : '';
        attrs.values.reverse();
        if (attrs.placeholder) {
          options += '<option disabled selected>' + attrs.placeholder + '</option>';
        }
        for (i = attrs.values.length - 1; i >= 0; i--) {
          var selected = attrs.values[i].selected && !attrs.placeholder ? 'selected' : '';
          options += '<option value="' + '1' + '" ' + selected + '>' + 'El contenido de  este Select es de un  Webservices' + '</option>';
        }
        preview = '<' + type + ' class="' + attrs.className + '" ' + multiple + '>' + options + '</' + type + '>';
        break;
      case 'selectnested':
        var options = '',
          type = attrs.type.replace('nested', ''),
          multiple = attrs.multiple ? 'multiple' : '';
        attrs.values.reverse();
        if (attrs.placeholder) {
          options += '<option disabled selected>' + attrs.placeholder + '</option>';
        }
        for (i = attrs.values.length - 2; i >= 0; i--) {
          var selected = attrs.values[i].selected && !attrs.placeholder ? 'selected' : '';
          options += '<option value="' + attrs.values[i].value + '" ' + selected + '>' + attrs.values[i].label + '</option>';
        }
        preview = '<' + type + ' class="' + attrs.className + '" ' + multiple + '>' + options + '</' + type + '>';
        break;
      case 'selectWSnested':
        var options = '';

        for (i = 0; i <= attrs.values.length - 1; i++) {

          options += '<label class="field-label">' + attrs.values[i].label + ' </label><br>'
          options += '<select class="form-control select">'
          options += '<option value="' + attrs.values[i].value + ' " ' + selected + '>' + attrs.values[i].value + '</option>';
          options += '</select>'
          options += '<br>'

        }
        preview = options;
        break;
      case 'elementsGroup1':
        var options = '',
          type = attrs.type.replace('nested', ''),
          multiple = attrs.multiple ? 'multiple' : '';
        attrs.values.reverse();
        if (attrs.placeholder) {
          options += '<option disabled selected>' + attrs.placeholder + '</option>';
        }
        for (i = attrs.values.length - 2; i >= 0; i--) {
          var selected = attrs.values[i].selected && !attrs.placeholder ? 'selected' : '';
          options += '<option value="' + attrs.values[i].value + '" ' + selected + '>' + attrs.values[i].label + '</option>';
        }
        preview = '<' + type + ' class="' + attrs.className + '" ' + multiple + '>' + options + '</' + type + '>';
        break;
      case 'checkbox-group':
      /*case 'radio-group':
        var type = attrs.type.replace('-group', ''),
          optionName = type + '-' + epoch;
        attrs.values.reverse();
        for (i = attrs.values.length - 1; i >= 0; i--) {
          var checked = attrs.values[i].selected ? 'checked' : '';
          var optionId = type + '-' + epoch + '-' + i;
          preview += '<div><input type="' + type + '" class="' + attrs.className + '" name="' + optionName + '" id="' + optionId + '" value="' + attrs.values[i].value + '" ' + checked + '/><label for="' + optionId + '">' + attrs.values[i].label + '</label></div>';
        }
        if (attrs.enableOther) {
          var otherID = optionName + '-other',
            optionAttrs = {
              id: otherID,
              name: optionName,
              className: attrs.className + ' other-option',
              type: type,
              onclick: 'otherOptionCallback(\'' + otherID + '\')'
            },
            otherInput = _helpers.markup('input', null, optionAttrs),
            optionAttrsString = _helpers.attrString(optionAttrs);

          window.otherOptionCallback = function (otherID) {
            var option = document.getElementById(otherID),
              otherLabel = option.nextElementSibling,
              otherInput = otherLabel.nextElementSibling;
            if (option.checked) {
              otherInput.style.display = 'inline-block';
              otherLabel.style.display = 'none';
            } else {
              otherInput.style.display = 'none';
              otherLabel.style.display = 'inline-block';
            }
          };

          preview += '<div>' + otherInput.outerHTML + '<label for="' + otherID + '">' + opts.messages.other + '</label> <input type="text" id="' + otherID + '-value" style="display:none;" /></div>';
        }

        break;*/
      case 'text':
      case 'password':
      case 'email':
      case 'date':
      case 'file':
        preview = '<input ' + attrsString + '>';
        break;
      case 'color':
        preview = '<input type="' + attrs.type + '" class="' + attrs.className + '"> ' + opts.messages.selectColor;
        break;
      case 'hidden':
      case 'checkbox':
        preview = '<input type="' + attrs.type + '" ' + toggle + ' >';
        break;
      case 'autocomplete':
        preview = '<input class="ui-autocomplete-input ' + attrs.className + '" autocomplete="on">';
        break;
      case 'hr':
        preview = '<' + attrs.type + '>' + '</' + attrs.type + '>';
        break;
      default:
        attrsString = _helpers.attrString(attrs);
        preview = '<' + attrs.type + ' ' + attrsString + '>' + attrs.label + '</' + attrs.type + '>';
    }

    return preview;
  };

  // update preview to label
  _helpers.updateMultipleSelect = function () {
    $(document.getElementById(opts.formID)).on('change', 'input[name="multiple"]', function () {
      var options = $(this).parents('.field-options:eq(0)').find('.sortable-options input.option-selected');
      if (this.checked) {
        options.each(function () {
          $(this).prop('type', 'checkbox');
        });
      } else {
        options.each(function () {
          $(this).removeAttr('checked').prop('type', 'radio');
        });
      }
    });
  };

  _helpers.debounce = function (func) {
    var wait = arguments.length <= 1 || arguments[1] === undefined ? 250 : arguments[1];
    var immediate = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function later() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  _helpers.htmlEncode = function (value) {
    return $('<div/>').text(value).html();
  };

  _helpers.htmlDecode = function (value) {
    return $('<div/>').html(value).text();
  };

  _helpers.validateForm = function () {
    var $form = $(document.getElementById(opts.formID));

    var errors = [];
    // check for empty field labels
    $('input[name="label"], input[type="text"].option', $form).each(function () {
      if ($(this).val() === '') {
        var field = $(this).parents('li.form-field'),
          fieldAttr = $(this);
        errors.push({
          field: field,
          error: opts.messages.labelEmpty,
          attribute: fieldAttr
        });
      }
    });

    // @todo add error = { noVal: opts.messages.labelEmpty }
    if (errors.length) {
      alert('Error: ' + errors[0].error);
      $('html, body').animate({
        scrollTop: errors[0].field.offset().top
      }, 1000, function () {
        var targetID = $('.toggle-form', errors[0].field).attr('id');
        $('.toggle-form', errors[0].field).addClass('open').parent().next('.prev-holder').slideUp(250);
        $('#' + targetID + '-fld').slideDown(250, function () {
          errors[0].attribute.addClass('error');
        });
      });
    }
  };

  /**
  * Display a custom tooltip for disabled fields.
  *
  * @param  {Object} field
  */
  _helpers.disabledTT = {
    className: 'frmb-tt',
    add: function add(field) {
      var title = opts.messages.fieldNonEditable;

      if (title) {
        var tt = _helpers.markup('p', title, { className: _helpers.disabledTT.className });
        field.append(tt);
      }
    },
    remove: function remove(field) {
      $('.frmb-tt', field).remove();
    }
  };

  _helpers.classNames = function (field, previewData) {
    var noFormControl = ['checkbox', 'checkbox-group', 'radio-group'],
      blockElements = ['header', 'footer', 'hr', 'paragraph', 'button'],
      i = void 0;

    for (i = blockElements.length - 1; i >= 0; i--) {
      blockElements = blockElements.concat(opts.messages.subtypes[blockElements[i]]);
    }

    noFormControl = noFormControl.concat(blockElements);

    var type = previewData.type;
    var style = previewData.style;
    var className = field[0].querySelector('.fld-className').value;
    var classes = [].concat(className.split(' ')).reverse();
    var types = {
      button: 'btn',
      submit: 'btn'
    };

    var primaryType = types[type];

    if (primaryType) {
      if (style) {
        for (i = classes.length - 1; i >= 0; i--) {
          var re = new RegExp('(?:^|\s)' + primaryType + '-(.*?)(?:\s|$)+', 'g');
          var match = classes[i].match(re);
          if (match) {
            classes.splice(i, 1);
          }
        }
        classes.push(primaryType + '-' + style);
      }
      classes.push(primaryType);
    } else if (!_helpers.inArray(type, noFormControl)) {
      classes.push('form-control');
    }

    // reverse the array to put custom classes at end, remove any duplicates, convert to string, remove whitespace
    return _helpers.unique(classes.reverse()).join(' ').trim();
  };

  _helpers.markup = function (tag) {
    var content = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];
    var attrs = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    var contentType = void 0,
      field = document.createElement(tag),
      getContentType = function getContentType(content) {
        return Array.isArray(content) ? 'array' : typeof content === 'undefined' ? 'undefined' : _typeof(content);
      },
      appendContent = {
        string: function string(content) {
          field.innerHTML = content;
        },
        object: function object(content) {
          return field.appendChild(content);
        },
        array: function array(content) {
          for (var i = 0; i < content.length; i++) {
            contentType = getContentType(content[i]);
            appendContent[contentType](content[i]);
          }
        }
      };

    for (var attr in attrs) {
      if (attrs.hasOwnProperty(attr)) {
        if (attrs[attr]) {
          var name = _helpers.safeAttrName(attr);
          field.setAttribute(name, attrs[attr]);
        }
      }
    }

    contentType = getContentType(content);

    if (content) {
      appendContent[contentType].call(this, content);
    }

    return field;
  };

  /**
  * Closes and open dialog
  *
  * @param  {Object} overlay Existing overlay if there is one
  * @param  {Object} dialog  Existing dialog
  * @return {Event}          Triggers modalClosed event
  */
  _helpers.closeConfirm = function (overlay, dialog) {
    overlay = overlay || document.getElementsByClassName('form-builder-overlay')[0];
    dialog = dialog || document.getElementsByClassName('form-builder-dialog')[0];
    overlay.classList.remove('visible');
    dialog.remove();
    overlay.remove();
    document.dispatchEvent(formBuilder.events.modalClosed);
  };

  _helpers.closeDialog = function () {
    $(".form-builder-overlay").hide();
    $(".form-builder-dialog").hide();
  };

  /**
  * Returns the layout data based on controlPosition option
  * @param  {String} controlPosition 'left' or 'right'
  * @return {Object}
  */
  _helpers.editorLayout = function (controlPosition) {
    var layoutMap = {
      left: {
        stage: 'pull-right',
        controls: 'pull-left'
      },
      right: {
        stage: 'pull-left',
        controls: 'pull-right'
      }
    };

    return layoutMap[controlPosition] ? layoutMap[controlPosition] : '';
  };

  /**
  * Adds overlay to the page. Used for modals.
  * @return {Object}
  */
  _helpers.showOverlay = function () {
    var overlay = _helpers.markup('div', null, {
      className: 'form-builder-overlay'
    });
    document.body.appendChild(overlay);
    overlay.classList.add('visible');

    overlay.onclick = function () {
      _helpers.closeConfirm(overlay);
    };

    return overlay;
  };

  /**
  * Custom confirmation dialog
  *
  * @param  {Object}  message   Content to be displayed in the dialog
  * @param  {Func}  yesAction callback to fire if they confirm
  * @param  {Boolean} coords    location to put the dialog
  * @param  {String}  className Custom class to be added to the dialog
  * @return {Object}            Reference to the modal
  */
  _helpers.confirm = function (message, yesAction) {
    var coords = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
    var className = arguments.length <= 3 || arguments[3] === undefined ? '' : arguments[3];

    var overlay = _helpers.showOverlay();
    var yes = _helpers.markup('button', opts.messages.yes, { className: 'yes btn btn-success btn-sm' }),
      no = _helpers.markup('button', opts.messages.no, { className: 'no btn btn-danger btn-sm' });

    no.onclick = function () {
      _helpers.closeConfirm(overlay);
    };

    yes.onclick = function () {
      yesAction();
      _helpers.closeConfirm(overlay);
    };

    var btnWrap = _helpers.markup('div', [no, yes], { className: 'button-wrap' });

    className = 'form-builder-dialog ' + className;

    var miniModal = _helpers.markup('div', [message, btnWrap], { className: className });
    if (!coords) {
      coords = {
        pageX: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) / 2,
        pageY: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 2
      };
      miniModal.style.position = 'fixed';
    } else {
      miniModal.classList.add('positioned');
    }

    miniModal.style.left = coords.pageX + 'px';
    miniModal.style.top = coords.pageY + 'px';

    document.body.appendChild(miniModal);

    yes.focus();
    return miniModal;
  };
  _helpers.dialog2 = function (message) {
    var coords = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
    var className = arguments.length <= 3 || arguments[3] === undefined ? '' : arguments[3];

    var overlay = _helpers.showOverlay();
    var ok = _helpers.markup('button', opts.messages.ok, { className: 'yes btn btn-success btn-sm' });

    ok.onclick = function () {
      // yesAction();
      _helpers.closeDialog(overlay);
    };

    var btnWrap = _helpers.markup('div', [ok], { className: 'button-wrap' });

    className = 'form-builder-dialog ' + className;

    var miniModal = _helpers.markup('div', [message, btnWrap], { className: className });
    if (!coords) {
      coords = {
        pageX: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) / 2,
        pageY: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 2
      };
      miniModal.style.position = 'fixed';
    } else {
      miniModal.classList.add('positioned');
    }

    miniModal.style.left = coords.pageX + 'px';
    miniModal.style.top = coords.pageY + 'px';

    document.body.appendChild(miniModal);

    ok.focus();
    return miniModal;
  };
  /**
  * Popup dialog the does not require confirmation.
  * @param  {String|DOM|Array}  content
  * @param  {Boolean} coords    false if no coords are provided. Without coordinates
  *                             the popup will appear center screen.
  * @param  {String}  className classname to be added to the dialog
  * @return {Object}            dom
  */
  _helpers.dialog = function (content) {
    var coords = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
    var className = arguments.length <= 2 || arguments[2] === undefined ? '' : arguments[2];

    _helpers.showOverlay();

    className = 'form-builder-dialog ' + className;

    var miniModal = _helpers.markup('div', content, { className: className });
    if (!coords) {
      coords = {
        pageX: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) / 2,
        pageY: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 2
      };
      miniModal.style.position = 'fixed';
    } else {
      miniModal.classList.add('positioned');
    }

    miniModal.style.left = coords.pageX + 'px';
    miniModal.style.top = coords.pageY + 'px';

    document.body.appendChild(miniModal);

    if (className.indexOf('data-dialog') !== -1) {
      document.dispatchEvent(formBuilder.events.viewData);
    }
    return miniModal;
  };

  /**
  * Removes all fields from the form
  */
  _helpers.removeAllfields = function () {
    var form = document.getElementById(opts.formID);
    var fields = form.querySelectorAll('li.form-field');
    var $fields = $(fields);
    var markEmptyArray = [];

    if (opts.prepend) {
      markEmptyArray.push(true);
    }

    if (opts.append) {
      markEmptyArray.push(true);
    }

    if (!markEmptyArray.some(function (elem) {
      return elem === true;
    })) {
      form.parentElement.classList.add('empty');
    }

    form.classList.add('removing');

    var outerHeight = 0;
    $fields.each(function () {
      outerHeight += $(this).outerHeight() + 3;
    });

    fields[0].style.marginTop = -outerHeight + 'px';

    setTimeout(function () {
      $fields.remove();
      document.getElementById(opts.formID).classList.remove('removing');
      _helpers.save();
    }, 500);
  };
  _helpers.validaFechaDDMMAAAA = function (fecha) {
    var dtCh = "/";
    var minYear = 1900;
    var maxYear = 2100;
    function isInteger(s) {
      var i;
      for (i = 0; i < s.length; i++) {
        var c = s.charAt(i);
        if (((c < "0") || (c > "9"))) return false;
      }
      return true;
    }
    function stripCharsInBag(s, bag) {
      var i;
      var returnString = "";
      for (i = 0; i < s.length; i++) {
        var c = s.charAt(i);
        if (bag.indexOf(c) == -1) returnString += c;
      }
      return returnString;
    }
    function daysInFebruary(year) {
      return (((year % 4 == 0) && ((!(year % 100 == 0)) || (year % 400 == 0))) ? 29 : 28);
    }
    function DaysArray(n) {
      for (var i = 1; i <= n; i++) {
        this[i] = '31';
        if (i == 4 || i == 6 || i == 9 || i == 11) { this[i] = 30 }
        if (i == 2) { this[i] = 29 }
      }
      return this
    }
    function isDate(dtStr) {
      var daysInMonth = DaysArray(12);
      var pos1 = dtStr.indexOf(dtCh);
      var pos2 = dtStr.indexOf(dtCh, pos1 + 1);
      var strDay = dtStr.substring(0, pos1);
      var strMonth = dtStr.substring(pos1 + 1, pos2);
      var strYear = dtStr.substring(pos2 + 1);
      strYr = strYear;
      if (strDay.charAt(0) == "0" && strDay.length > 1) strDay = strDay.substring(1);
      if (strMonth.charAt(0) == "0" && strMonth.length > 1) strMonth = strMonth.substring(1);
      for (var i = 1; i <= 3; i++) {
        if (strYr.charAt(0) == "0" && strYr.length > 1) strYr = strYr.substring(1);
      }
      month = parseInt(strMonth);
      day = parseInt(strDay);
      year = parseInt(strYr);
      if (pos1 == -1 || pos2 == -1) {
        return false;
      }
      if (strMonth.length < 1 || month < 1 || month > 12) {
        return false;
      }
      if (strDay.length < 1 || day < 1 || day > 31 || (month == 2 && day > daysInFebruary(year)) || day > daysInMonth[month]) {
        return false;
      }
      if (strYear.length != 4 || year == 0 || year < minYear || year > maxYear) {
        return false;
      }
      if (dtStr.indexOf(dtCh, pos2 + 1) != -1 || isInteger(stripCharsInBag(dtStr, dtCh)) == false) {
        return false;
      }
      return true;
    }
    if (isDate(fecha)) {
      return true;
    } else {
      return false;
    }
  }
  /**
  * If user re-orders the elements their order should be saved.
  *
  * @param {Object} $cbUL our list of elements
  */
  _helpers.setFieldOrder = function ($cbUL) {
    if (!opts.sortableControls) {
      return false;
    }
    var fieldOrder = {};
    $cbUL.children().each(function (index, element) {
      fieldOrder[index] = $(element).data('attrs').type;
    });
    if (window.sessionStorage) {
      window.sessionStorage.setItem('fieldOrder', window.JSON.stringify(fieldOrder));
    }
  };

  /**
  * Reorder the controls if the user has previously ordered them.
  *
  * @param  {Array} frmbFields
  * @return {Array}
  */
  _helpers.orderFields = function (frmbFields) {
    var fieldOrder = true;

    if (window.sessionStorage) {
      if (opts.sortableControls) {
        fieldOrder = window.sessionStorage.getItem('fieldOrder');
      } else {
        window.sessionStorage.removeItem('fieldOrder');
      }
    }

    if (!fieldOrder) {
      fieldOrder = _helpers.unique(opts.controlOrder);
    } else {
      fieldOrder = window.JSON.parse(fieldOrder);
      fieldOrder = Object.keys(fieldOrder).map(function (i) {
        return fieldOrder[i];
      });
    }

    var newOrderFields = [];

    for (var i = fieldOrder.length - 1; i >= 0; i--) {
      var field = frmbFields.filter(function (field) {
        return field.attrs.type === fieldOrder[i];
      })[0];
      newOrderFields.push(field);
    }

    return newOrderFields.filter(Boolean);
  };

  // forEach that can be used on nodeList
  _helpers.forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  };

  // cleaner syntax for testing indexOf element
  _helpers.inArray = function (needle, haystack) {
    return haystack.indexOf(needle) !== -1;
  };

  /**
   * Remove duplicates from an array of elements
   * @param  {array} arrArg array with possible duplicates
   * @return {array}        array with only unique values
   */
  _helpers.unique = function (array) {
    return array.filter(function (elem, pos, arr) {
      return arr.indexOf(elem) === pos;
    });
  };
  return _helpers;
}
'use strict';

function formBuilderEventsFn() {
  'use strict';

  var events = {};

  events.loaded = new Event('loaded');
  events.viewData = new Event('viewData');
  events.userDeclined = new Event('userDeclined');
  events.modalClosed = new Event('modalClosed');
  events.formSaved = new Event('formSaved');

  return events;
}
'use strict';
var elem;
(function ($) {
  'use strict';

  var Toggle = function Toggle(element, options) {

    var defaults = {
      theme: 'fresh',
      labels: {
        off: 'Off',
        on: 'On'
      }
    };

    var opts = $.extend(defaults, options),
      $kcToggle = $('<div class="kc-toggle"/>').insertAfter(element).append(element);

    $kcToggle.toggleClass('on', element.is(':checked'));

    var kctOn = '<div class="kct-on">' + opts.labels.on + '</div>',
      kctOff = '<div class="kct-off">' + opts.labels.off + '</div>',
      kctHandle = '<div class="kct-handle"></div>',
      kctInner = '<div class="kct-inner">' + kctOn + kctHandle + kctOff + '</div>';

    $kcToggle.append(kctInner);

    $kcToggle.click(function () {
      element.attr('checked', !element.attr('checked'));
      $(this).toggleClass('on');
    });
  };

  $.fn.kcToggle = function (options) {
    var toggle = this;
    return toggle.each(function () {
      var element = $(this);
      if (element.data('kcToggle')) {
        return;
      }
      var kcToggle = new Toggle(element, options);
      element.data('kcToggle', kcToggle);
    });
  };
})(jQuery);
'use strict';

(function ($) {
  var FormBuilder = function FormBuilder(options, element) {
    var formBuilder = this;
    var numberEleInitial = 0;
    var numberEleCtrl = 0;
    var Consecutivo = 0;
    var stepperCountGlobal = 0;
    var ConsecutivoNested = 0;
    var defaults = {
      controlPosition: 'right',
      controlOrder: ['autocomplete', 'button', 'checkbox', 'checkbox-group', 'date', 'file', 'header', 'footer', 'hidden', 'paragraph', 'radio-group', 'select', 'text', 'textarea', 'selectws', 'selectnested', 'selectWSnested', 'hr', 'elementsGroup'],
      dataType: 'xml',
      /**
      * Field types to be disabled
      * ['text','select','textarea','radio-group','hidden','file','date','checkbox-group','checkbox','button','autocomplete']
      */
      disableFields: ['autocomplete', 'hidden'],
      // Uneditable fields or other content you would like to appear before and after regular fields:
      append: false,
      prepend: false,
      // array of objects with fields values
      // ex:
      // defaultFields: [{
      //   label: 'First Name',
      //   name: 'first-name',
      //   required: 'true',
      //   description: 'Your first name',
      //   type: 'text'
      // }, {
      //   label: 'Phone',
      //   name: 'phone',
      //   description: 'How can we reach you?',
      //   type: 'text'
      // }],
      defaultFields: [],
      fieldRemoveWarn: false,
      roles: {
        1: 'Administrator'
      },
      messages: {
        addOption: 'Agregar más opciones',
        addOptionSelect: 'Agregar más opciones',
        addOptionElementsGroup: 'Agregar elemento',
        delOptionElementsGroup: 'Eliminar elemento',
        addOptionSelectElementsGroup: 'Agregar opciones',
        allFieldsRemoved: 'Remover todos los elementos del formulario.',
        allowSelect: 'Allow Select',
        autocomplete: 'Autocompletar',
        button: 'Botón',
        cannotBeEmpty: 'Este campo no puede estar vacío y tiene que ser único',
        checkboxGroup: 'Opción múltiple ',
        checkbox: 'Checkbox',
        checkboxes: 'Checkboxes',
        className: 'Clase',
        clearAllMessage: '¿Esta seguro de querer limpiar el formulario?',
        saveAllMessage: 'Se  guardará el formulario con los últimos cambios, verifique el estado y el tipo de trámite. ¿Desea continuar?',
        clearAll: 'Limpiar formulario',
        close: 'Cerrar',
        content: 'Contenido',
        copy: 'Copy To Clipboard',
        dateField: 'Fecha',
        description: 'Texto de ayuda',
        descriptionField: 'Descripción',
        devMode: 'Developer Mode',
        editNames: 'Editar nombres',
        editorTitle: 'Elementos del formulario',
        editXML: 'Editar XML',
        enableOther: 'Enable &quot;Other&quot;',
        enableOtherMsg: 'Permit users to enter an unlisted option',
        fieldDeleteWarning: false,
        fieldVars: 'Field Variables',
        fieldNonEditable: 'This field cannot be edited.',
        fieldRemoveWarning: '¿Está seguro de que desea borrar todos los campos?',
        fileUpload: 'File Upload',
        formUpdated: 'Form Updated',
        getStarted: 'Arrastre un campo desde la derecha a esta área',
        header: 'Etiqueta, Encabezado, Pie de página, Mensaje final, Secciones',
        footer: 'Footer',
        hide: 'Editar',
        hidden: 'Hidden Input',
        label: 'Etiqueta',
        labelnested: 'Etiqueta 1  ',
        labelS: 'Etiqueta Select2',
        labelEmpty: 'Field Label cannot be empty',
        limitRole: 'Limitar accesos a los siguientes roles:',
        mandatory: 'Mandatory',
        maxlength: 'Longitud máxima',
        minOptionMessage: 'This field requires a minimum of 2 options',
        name: 'Nombre',
        no: 'No',
        off: 'Off',
        on: 'On',
        option: 'Opción',
        option_value: 'Opcion',
        optional: 'Opcional',
        optionLabelPlaceholder: 'Etiqueta sugerir',
        optionValuePlaceholder: 'Valor',
        optionEmpty: 'Opción value requerida',
        paragraph: 'Paragraph',
        placeholder: 'Placeholder',
        placeholders: {
          value: 'Valor',
          label: 'Etiqueta',
          labelws: 'Etiqueta 2',
          name: 'Nombre',
          text: '',
          textarea: '',
          email: 'Enter you email',
          placeholder: '',
          className: 'space separated classes',
          password: 'Enter your password'
        },
        preview: 'Preview',
        radioGroup: 'Opción única',
        plantillas: 'Plantillas',
        radio: 'Radio',
        removeMessage: 'Remover elemento',
        remove: '&#215;',
        required: 'Campo requerido',
        dato_abierto: 'Dato abierto',
        richText: 'Rich Text Editor',
        roles: 'Accesos',
        save: 'Guardar formulario',
        selectOptions: 'Opciones',
        selectws_label: ' Webservices',
        select: 'Desplegable',
        selectws: 'Estados y municipios',
        elementType: 'Tipo elemento',
        hr: 'Línea de separación',
        selectnested: 'Selección anidada',
        select_WS_nested: 'Webservices anidados',
        elementsGroup: 'Grupo de elementos',
        selectColor: 'Select Color',
        selectionsMessage: 'Permitir selecciones múltiples',
        size: 'Size',
        sizes: {
          xs: 'Extra Small',
          sm: 'Small',
          m: 'Default',
          lg: 'Large'
        },
        style: 'Style',
        styles: {
          btn: {
            'default': 'Default',
            danger: 'Danger',
            info: 'Info',
            primary: 'Primary',
            success: 'Success',
            warning: 'Warning'
          }
        },
        elementsGroupElements: ['Desplegable', 'Opción múltiple', 'Texto corto', 'Texto largo'],
        elementsGroupElementsValue: ['select', 'checkbox-group', 'text', 'textarea'],
        subtype: 'Tipo',
        fontsize: 'Tamaño de letra',
        importancia: 'Consecutivo',
        subtypes: {
          text: ['text', 'password'],
          button: ['button', 'submit'],
          header: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          elementType: ['Etiqueta', 'Encabezado', 'Pie de página', 'Mensaje final', 'Secciones', 'Párrafo'],
          elementTypeValue: ['Label', 'Header', 'Footer', 'messageFinal', 'Stepper', 'p'],
          footer: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          importancia: ['No', 'Si'],
          paragraph: ['p', 'address', 'blockquote', 'canvas', 'output']
        }, regex: 'Validar como',
        regexs: {
          values: ['Sin validaci&oacute;n', 'Alfanum&eacute;rico', 'CURP', 'Correo electr&oacute;nico', 'RFC', 'S&oacute;lo n&uacute;meros', 'S&oacute;lo letras', 'C&oacute;digo postal']
        },
        numStepper: 'Stepper',
        Steppers: {
          values: ['Primer Stepper', 'Segundo Steppers', 'Tercer  Steppers', 'Cuarto Steppers', 'Quinto Steppers', 'Sexto Steppers']
        },
        numGroup: 'Agrupado',
        groups: {
          values: ['Sin agrupamiento', 'Primer agrupado', 'Segundo agrupado', 'Tercer  agrupado', 'Cuarto agrupado', 'Quinto agrupado', 'Sexto agrupado']
        },
        text: 'Texto corto',
        textArea: 'Texto largo',
        toggle: 'Toggle',
        warning: 'Warning!',
        viewXML: 'Ver codigo fuente;',
        yes: 'Si',
        ok: 'Ok'
      },
      notify: {
        error: function error(message) {
          return console.error(message);
        },
        success: function success(message) {
          return console.log(message);
        },
        warning: function warning(message) {
          return console.warn(message);
        }
      },
      sortableControls: false,
      prefix: 'form-builder-'
    };

    // @todo function to set parent types for subtypes
    defaults.messages.subtypes.password = defaults.messages.subtypes.text;
    defaults.messages.subtypes.email = defaults.messages.subtypes.text;
    defaults.messages.subtypes.color = defaults.messages.subtypes.text;
    defaults.messages.subtypes.submit = defaults.messages.subtypes.button;

    var opts = $.extend(true, defaults, options),
      elem = $(element),
      frmbID = 'frmb-' + $('ul[id^=frmb-]').length++;

    opts.formID = frmbID;

    formBuilder.element = element;

    var $sortableFields = $('<ul/>').attr('id', frmbID).addClass('frmb');

    _helpers = formBuilderHelpersFn(opts, formBuilder); // Se inicializa

    formBuilder.layout = _helpers.editorLayout(opts.controlPosition);

    var lastID = frmbID + '-fld-1',
      boxID = frmbID + '-control-box';

    // create array of field objects to cycle through
    var frmbFields = [
      {
        label: opts.messages.hr,
        attrs: {
          type: 'hr',
          className: 'hidden-input'
        }
      },
      {
        label: opts.messages.button,
        attrs: {
          type: 'button',
          className: 'button-input',
          name: 'button'
        }
      },{
        label: opts.messages.fileUpload,
        attrs: {
          type: 'file',
          className: 'text-input',
          name: 'file'
        }
      },
      /*
      {
        label: opts.messages.selectws,
        attrs: {
          type: 'selectws',
          className: 'select'

        }
     }
     ,*/
      {
        label: opts.messages.select_WS_nested,
        attrs: {
          type: 'selectWSnested',
          className: 'select'

        }
      }, {
        label: opts.messages.elementsGroup,
        attrs: {
          type: 'elementsGroup',
          className: 'text-input',
          name: 'elementsgroup'
        }
      },
      {
        label: opts.messages.selectnested,
        attrs: {
          type: 'selectnested',
          className: 'select'

        }
      }, {
        label: opts.messages.textArea,
        attrs: {
          type: 'textarea',
          className: 'text-area',
          name: 'textarea'
        }
      }, {
        label: opts.messages.text,
        attrs: {
          type: 'text',
          className: 'text-input',
          name: 'text-input'
        }
      },
      {
        label: opts.messages.select,
        attrs: {
          type: 'select',
          className: 'select',
          name: 'select'
        }
      },
      {
        label: opts.messages.checkboxGroup,
        attrs: {
          type: 'checkbox-group',
          className: 'checkbox-group',
          name: 'checkbox-group'
        }
      }, {
        label: opts.messages.radioGroup,
        attrs: {
          type: 'radio-group',
          className: 'radio-group',
          name: 'radio-group'
        }
      }, {
        label: opts.messages.dateField,
        attrs: {
          type: 'date',
          className: 'calendar',
          name: 'date-input'
        }
      }, {
        label: opts.messages.header,
        attrs: {
          type: 'header',
          className: 'header'
        }
      } /*{
              label: opts.messages.autocomplete,
              attrs: {
                type: 'autocomplete',
                className: 'autocomplete',
                name: 'autocomplete'
              }
            }*/];
    if (options != undefined) {
      if (parseInt(localStorage.getItem('statusForm')) === 3) {
        frmbFields = [
          {
            label: opts.messages.hr,
            attrs: {
              type: 'hr',
              className: 'hidden-input'
            }
          },
          {
            label: opts.messages.button,
            attrs: {
              type: 'button',
              className: 'button-input',
              name: 'button'
            }
          },
          {
            label: opts.messages.header,
            attrs: {
              type: 'header',
              className: 'header'
            }
          }
        ]
      }
    }

    //frmbFields = _helpers.orderFields(frmbFields);

    if (opts.disableFields) {
      // remove disabledFields
      frmbFields = frmbFields.filter(function (field) {
        return !_helpers.inArray(field.attrs.type, opts.disableFields);
      });
    }

    // Create draggable fields for formBuilder

    var cbUl = _helpers.markup('ul', null, { id: boxID, className: 'frmb-control' });

    if (opts.sortableControls) {
      cbUl.classList.add('sort-enabled');
    }

    var $cbUL = $(cbUl);

    // Loop through
    for (var i = frmbFields.length - 1; i >= 0; i--) {

      var $field = $('<li/>', {
        'class': 'iconform-' + frmbFields[i].attrs.className,
        'type': frmbFields[i].type,
        'name': frmbFields[i].className,
        'label': frmbFields[i].label
      });

      for (var attr in frmbFields[i]) {
        if (frmbFields[i].hasOwnProperty(attr)) {
          $field.data(attr, frmbFields[i][attr]);
        }
      }

      var typeLabel = _helpers.markup('span', frmbFields[i].label);
      $field.html(typeLabel).appendTo($cbUL);
    }

    var viewDataText = opts.dataType === 'xml' ? opts.messages.viewXML : opts.messages.viewJSON;

    // Build our headers and action links
    var viewData = _helpers.markup('button', viewDataText, {
      id: frmbID + '-view-data',
      type: 'button',
      className: 'view-data btn btn-default'
    }),
      clearAll = _helpers.markup('button', opts.messages.clearAll, {
        id: frmbID + '-clear-all',
        type: 'button',
        className: 'clear-all btn btn-default'
      }),
      saveAll = _helpers.markup('input', "", {
        id: 'generateJSON',
        type: 'hidden'
      }),
      formActions = _helpers.markup('div', [clearAll, saveAll], {
        className: 'form-actions btn-group'
      }).outerHTML;

    // Sortable fields
    $sortableFields.sortable({
      cursor: 'move',
      opacity: 0.9,
      revert: 150,
      beforeStop: _helpers.beforeStop,
      start: _helpers.startMoving,
      stop: _helpers.stopMoving,
      cancel: 'input, select, .disabled, .form-group, .btn',
      placeholder: 'frmb-placeholder'
    });

    // ControlBox with different fields
    $cbUL.sortable({
      helper: 'clone',
      opacity: 0.9,
      connectWith: $sortableFields,
      cursor: 'move',
      placeholder: 'ui-state-highlight',
      start: _helpers.startMoving,
      stop: _helpers.stopMoving,
      revert: 150,
      beforeStop: _helpers.beforeStop,
      update: function update(event, ui) {
        if (_helpers.doCancel) {
          return false;
        }
        event = event;
        if (ui.item.parent()[0] === $sortableFields[0]) {
          prepFieldVars(ui.item, true);
          _helpers.doCancel = true;
        } else {
          _helpers.setFieldOrder($cbUL);
          _helpers.doCancel = !opts.sortableControls;
        }
      }
    });

    var $stageWrap = $('<div/>', {
      id: frmbID + '-stage-wrap',
      'class': 'stage-wrap ' + formBuilder.layout.stage
      , onchange: "document.getElementById('generateJSON').click();"

    });

    var $formWrap = $('<div/>', {
      id: frmbID + '-form-wrap',
      'class': 'form-wrap form-builder' + _helpers.mobileClass()
    });

    elem.before($stageWrap).appendTo($stageWrap);

    var cbWrap = $('<div/>', {
      id: frmbID + '-cb-wrap',
      'class': 'flotante cb-wrap ' + formBuilder.layout.controls
    }).append($cbUL[0], formActions);

    $stageWrap.append($sortableFields, cbWrap);
    $stageWrap.before($formWrap);
    $formWrap.append($stageWrap, cbWrap);

    var saveAndUpdate = _helpers.debounce(function (evt) {
      if (evt) {
        if (evt.type === 'keyup' && this.name === 'className') {
          return false;
        }
      }
      var $field = $(this).parents('.form-field:eq(0)');
      _helpers.updatePreview($field);
      _helpers.save();
    });

    // Save field on change
    $sortableFields.on('change blur keyup', '.form-elements input, .form-elements select, .form-elements textarea', saveAndUpdate);

    // Add append and prepend options if necessary
    var nonEditableFields = function nonEditableFields() {
      var cancelArray = [];

      if (opts.prepend && !$('.disabled.prepend', $sortableFields).length) {
        var prependedField = _helpers.markup('li', opts.prepend, { className: 'disabled prepend' });
        cancelArray.push(true);
        $sortableFields.prepend(prependedField);
      }

      if (opts.append && !$('.disabled.append', $sortableFields).length) {
        var appendedField = _helpers.markup('li', opts.append, { className: 'disabled append' });
        cancelArray.push(true);
        $sortableFields.append(appendedField);
      }

      if (cancelArray.some(function (elem) {
        return elem === true;
      })) {
        $stageWrap.removeClass('empty');
      }
    };

    var prepFieldVars = function prepFieldVars($field) {
      var isNew = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var fieldAttrs = $field.data('attrs') || {},
        fType = fieldAttrs.type || $field.attr('type'),
        values = {};

      values.label = _helpers.htmlEncode($field.attr('label'));
      values.name = isNew ? nameAttr($field) : fieldAttrs.type || $field.attr('type');
      values.role = $field.attr('role');
      values.className = fieldAttrs.className || $field.attr('className');

      values.required = $field.attr('required');
      values.maxlength = $field.attr('maxlength');
      values.toggle = $field.attr('toggle');
      values.multiple = fType.match(/(checkbox-group)/);
      values.type = fType;
      values.description = $field.attr('description') !== undefined ? _helpers.htmlEncode($field.attr('description')) : '';
      //alert (values.description);
      if (!isNew) {
        values.values = [];
        $field.children().each(function () {
          var value = {
            required: true,
            label: $(this).text(),
            description: $(this).text(),
            value: $(this).attr('value'),
            selected: Boolean($(this).attr('selected'))
          };
          values.values.push(value);
        });
      }

      var match = /(?:^|\s)btn-(.*?)(?:\s|$)/g.exec(values.className);
      if (match) {
        values.style = match[1];

      }

      appendNewField(values);
      $stageWrap.removeClass('empty');
    };

    // Parse saved XML template data
    var getXML = function getXML() {
      var xml = '';
      if (formBuilder.formData) {
        xml = formBuilder.formData;
      } else if (elem.val() !== '') {
        xml = $.parseXML(formBuilder.element.value.trim());
      } else {
        xml = false;
      }

      var fields = $(xml).find('field');
      if (fields.length > 0) {
        formBuilder.formData = xml;
        fields.each(function () {
          prepFieldVars($(this));
        });
      } else if (!xml) {
        // Load default fields if none are set
        if (opts.defaultFields && opts.defaultFields.length) {
          opts.defaultFields.reverse();
          for (var i = opts.defaultFields.length - 1; i >= 0; i--) {
            appendNewField(opts.defaultFields[i]);
          }
          $stageWrap.removeClass('empty');
          _helpers.save();
        } else if (!opts.prepend && !opts.append) {
          $stageWrap.addClass('empty').attr('data-content', opts.messages.getStarted);
        }
      }

      $('li.form-field:not(.disabled)', $sortableFields).each(function () {
        _helpers.updatePreview($(this));
      });

      nonEditableFields();
    };

    var loadData = function loadData() {

      var doLoadData = {
        xml: getXML,
        json: function json() {
          console.log('coming soon');
        }
      };

      doLoadData[opts.dataType]();
    };

    // callback to track disabled tooltips
    $sortableFields.on('mousemove', 'li.disabled', function (e) {
      $('.frmb-tt', this).css({
        left: e.offsetX - 16,
        top: e.offsetY - 34
      });
    });

    // callback to call disabled tooltips
    $sortableFields.on('mouseenter', 'li.disabled', function () {
      _helpers.disabledTT.add($(this));
    });

    // callback to call disabled tooltips
    $sortableFields.on('mouseleave', 'li.disabled', function () {
      _helpers.disabledTT.remove($(this));
    });

    var nameAttr = function nameAttr(field) {
      var epoch = new Date().getTime();
      return field.data('attrs').type + '-' + epoch;
    };

    // multi-line textarea
    var appendTextarea = function appendTextarea(values) {
      appendFieldLi(opts.messages.textArea, advFields(values), values);
    };

    var appendInput = function appendInput(values) {
      var type = values.type || 'text';
      appendFieldLi(opts.messages[type], advFields(values), values);
    };

    /**
    * Add data for field with options [select, checkbox-group, radio-group]
    *
    * @todo   refactor this nasty crap, its actually painful to look at
    * @param  {object} values
    */
    var appendSelectList = function appendSelectList(values) {
      console.log('appendSelectList fn => ', values);
      if (values.options != undefined) {
        values.values = JSON.parse(values.options);
      }
      if (!values.values || !values.values.length) {
        values.values = [{
          selected: true
        }, {
          selected: false
        }];

        values.values = values.values.map(function (elem, index) {
          elem.label = opts.messages.option + ' ' + (index + 1);
          elem.value = _helpers.hyphenCase(opts.messages.option_value + ' ' + (index + 1));
          return elem;
        });
      }

      var field = '';

      field += advFields(values);
      field += '<div class="form-group field-options ' + ClassDisabled() + '">';
      field += '<label class="false-label">' + opts.messages.selectOptions + '</label>';
      field += '<div class="sortable-options-wrap">';
      if (values.type === 'select' || values.type === 'selectws' || values.type === 'selectnested') {
        /* field += '<div class="allow-multi">';
         field += '<input type="checkbox" id="multiple_' + lastID + '" name="multiple"' + (values.multiple ? 'checked="checked"' : '') + '>';
         field += '<label class="multiple" for="multiple_' + lastID + '">' + opts.messages.selectionsMessage + '</label>';
         field += '</div>';
         */
      }
      var addOption = _helpers.markup('a', opts.messages.addOption, { className: 'add add-opt' });
      field += _helpers.markup('div', addOption, { className: 'option-actions' }).outerHTML;

      field += '<ol class="sortable-options">';
      for (i = 0; i < values.values.length; i++) {
        values.values[i].label = _helpers.htmlDecode(values.values[i].label);
        values.values[i].value = _helpers.htmlDecode(values.values[i].value);

        field += selectFieldOptions(values.name, values.values[i], values.values[i].selected, values.multiple);
      }
      field += '</ol>';

      field += '</div>';
      field += '</div>';

      appendFieldLi(opts.messages.select, field, values);

      $('.sortable-options').sortable(); // making the dynamically added option fields sortable.
    };

    /*Agregar plantilla predefinida*/
    var appendLayer = function appendLayer(values) {
      if (!values.values || !values.values.length) {
        values.values = [{
          selected: true
        }, {
          selected: false
        }];

        values.values = values.values.map(function (elem, index) {
          elem.label = opts.messages.option + ' ' + (index + 1);
          elem.value = _helpers.hyphenCase(elem.label);
          return elem;
        });
      }

      var field = '';

      field += advFields(values);
      field += '<div class="form-group field-options ' + ClassDisabled(values.type) + '">';
      field += '<label class="false-label">' + opts.messages.selectOptions + '</label>';
      field += '<div class="sortable-options-wrap">';

      if (values.type === 'select' || values.type === 'selectws' || values.type === 'selectnested' || values.type === 'selectWSnested') {
        field += '<div class="allow-multi">';
        field += '<input type="checkbox" id="multiple_' + lastID + '" name="multiple"' + (values.multiple ? 'checked="checked"' : '') + '>';
        field += '<label class="multiple" for="multiple_' + lastID + '">' + opts.messages.selectionsMessage + '</label>';
        field += '</div>';
      }
      field += '<ol class="sortable-options">';
      for (i = 0; i < values.values.length; i++) {
        field += selectFieldOptions(values.name, values.values[i], values.values[i].selected, values.multiple);
      }
      field += '</ol>';
      var addOption = _helpers.markup('a', opts.messages.addOption, { className: 'add add-opt' });
      field += _helpers.markup('div', addOption, { className: 'option-actions' }).outerHTML;
      field += '</div>';
      field += '</div>';

      appendFieldLi(opts.messages.select, field, values);

      $('.sortable-options').sortable(); // making the dynamically added option fields sortable.
    };

    // Hijos Sub
    var childProperties = function childProperties(selectvalue, selectlabel, fieldPadre, fieldCount) {
      var field = '<dl class = "children-values" id = ' + fieldCount + '>';
      // field += '<input type="radio" class="option-selected" name="undefined-'+FieldCount+'">';
      if (selectvalue !== undefined && selectlabel !== undefined) {
        field += '<input type="text" id ="labelSelect-' + fieldPadre + fieldCount + '"  class="option-label" placeholder="Etiqueta" value="' + selectlabel + '" name="' + selectlabel + '">';
        field += '<input type="text"  id ="value-' + fieldPadre + fieldCount + '" class="option-value" placeholder="Valor" value="' + selectvalue + '" name="' + selectvalue + '"  onkeypress="return valida(event)">';

      } else {
        field += '<input type="text" id ="labelSelect-' + fieldPadre + fieldCount + '"  class="option-label" placeholder="Etiqueta" value="Opción ' + fieldCount + '" name="selecthijo-' + fieldCount + '">';
        field += '<input type="text"  id ="value-' + fieldPadre + fieldCount + '" class="option-value" placeholder="Valor" value="Opcion-' + fieldCount + '" name="selecthijo-' + fieldCount + '" onkeypress="return valida(event)">';
      }
      // if (fieldCount >=2){
      field += '<a class="removes btn_remove elim-opt-nested" title="Remover Elemento">×</a>';
      //  }
      field += '</dl>';
      return field;
    };
    //Agrega Los valores de opciones de cada uno de los webservices que se van a incrustar
    var appendSelectListWSNested = function appendSelectListWSNested(values) {
      if (values.wsList != undefined) {
        values.wsList = JSON.parse(values.wsList);
      }

      if (!values.wsList || !values.wsList.length) {
        values.wsList = [{
          selected: true
        }, {
          selected: false
        }];

        values.wsList = values.wsList.map(function (elem, index) {
          elem.label = opts.messages.option + ' ' + (index + 1);
          elem.value = _helpers.hyphenCase(opts.messages.option_value + (index + 1));
          return elem;
        });
      }

      var field = '';

      field += advFields(values);
      field += '<div class="form-group field-options ' + ClassDisabled(values.type) + '">';
      field += '<label class="false-label">' + opts.messages.selectws_label + '</label>';
      field += '<ol class="sortable-options" style="width:81.33333333%">';
      //field +='<div>';
      var addOption = _helpers.markup('a', opts.messages.addOptionSelect, { className: 'add add-opt-parents-ws' });
      field += _helpers.markup('dt', addOption, { className: 'option-actions head-element' }).outerHTML;
      //field += '</div>';
      for (i = 0; i < values.wsList.length; i++) {
        con = i + 1;
        var value = values.wsList[i].value;
        var label = values.wsList[i].label;
        // var wsName = values.values[i].ws_name;
        var wsName = { wsname: "" }
        wsName.wsname = values.wsList[i].name;

        field += '<li id="consect-' + (i + 1) + '">';
        field += '<a class="btn_remove elim-opt-nested del-element" title="Remover Elemento">×</a>';
        field += '<label class="false-label">Etiqueta</label>';
        field += '<input type="hidden" id="consectLabel-' + con + '" class="option-wsname" placeholder="Etiqueta" value="' + wsName + '" name="' + wsName + '">';
        field += '<input type="text" id="consectLabel-' + con + '" class="option-label" placeholder="Etiqueta" value="' + label + '" name="' + label + '" >';
        field += '<br><br>';
        field += '<label class="false-label">Valor</label>';
        field += '<input type="text" id="consectValue-' + con + '" class="option-value" placeholder="Valor" value="' + value + '" name="' + value + '" onkeypress="return valida(event)">';
        field += '<br><br>';


        var wss = wslist(wsName);
        field += wss;
        field += '</ul>'; field += '</li>';
      }


      field += '</ol>';
      // making the dynamically added option fields sortable.
      field += '</div>';
      var items = $('.sortable-options').find('li').map(function () {
        var item = {};

        item.id = this.value;
        item.name = $(this).text();

        return item;
      });
      // alert ('values ----> '+JSON.stringify(values.values));
      appendFieldLi(opts.messages.select, field, values);

      $('.sortable-options').sortable(); // making the dynamically added option fields sortable.
    };
    /* Funcion para agregar  selects anidados*/
    var appendSelectListNested = function appendSelectListNested(values) {
      if (values.options != undefined) {
        values.values = JSON.parse(values.options);
      }

      if (!values.values || !values.values.length) {
        values.values = [{
          selected: true
        }, {
          selected: false
        }];

        values.values = values.values.map(function (elem, index) {
          elem.label = opts.messages.option + ' ' + (index + 1);
          elem.value = _helpers.hyphenCase(opts.messages.option_value + (index + 1));
          return elem;
        });
      }

      var field = '';

      field += advFields(values);
      field += '<div class="form-group field-options ' + ClassDisabled(values.type) + '">';
      field += '<label class="false-label">' + opts.messages.selectOptions + '</label>';
      field += '<ol class="sortable-options" style="width:81.33333333%">';
      //field +='<div>';
      var addOption = _helpers.markup('a', opts.messages.addOptionSelect, { className: 'add add-opt-parents' });
      field += _helpers.markup('dt', addOption, { className: 'option-actions head-element' }).outerHTML;
      //field += '</div>';
      console.log('nested');
      for (i = 0; i < values.values.length; i++) {
        con = i + 1;
        var value = values.values[i].value;
        var label = values.values[i].label;
        field += '<li id="consect-' + (i + 1) + '">';
        field += '<a class="btn_remove del-element " title="Remover Elemento">×</a>';
        field += '<input type="text" id="consectLabel-' + con + '"  class="option-label" placeholder="Etiqueta" value="' + label + '" name="' + label + '">';
        field += '<input type="text" id="consectValue-' + con + '" class="option-value" placeholder="Valor" value="' + value + '" name="' + value + '"  onkeypress="return valida(event)">';
        field += '<ul class= "childrens">'
        var addOption = _helpers.markup('a', opts.messages.addOption, { className: 'add add-opt-nested' });
        field += _helpers.markup('ul', addOption, { className: 'option-actions' }).outerHTML;


        if (values.values[i].sub_options !== undefined && values.values[i].sub_options !== null) {


          var lengthsub = (values.values[i].sub_options.length - 1);
          //---------------------------------------
          if (lengthsub >= 0) {

            for (var j = 0; j <= lengthsub; j++) {
              var select2value = values.values[i].sub_options[j].value;
              var select2label = values.values[i].sub_options[j].label;

              field += childProperties(select2value, select2label, (i + 1), (j + 1));
            }
          }
        } else if (values.values[i].sub_values === null) {
        }
        else if (values.dato_abierto === undefined) {
          field += childProperties(undefined, undefined, con, 1);
          field += childProperties(undefined, undefined, con, 2);

        }
        field += '</ul>';
        field += '</li>';
      }


      field += '</ol>';
      // making the dynamically added option fields sortable.
      field += '</div>';
      var items = $('.sortable-options').find('li').map(function () {
        var item = {};

        item.id = this.value;
        item.name = $(this).text();

        return item;
      });
      // alert ('values ----> '+JSON.stringify(values.values));
      appendFieldLi(opts.messages.select, field, values);

      $('.sortable-options').sortable(); // making the dynamically added option fields sortable.
    };

    var appendElementsGroup = function appendElementsGroup(values) {
      numberEleInitial = numberEleInitial + 1;

      localStorage.setItem('initialElements', numberEleInitial);
      if (!values.values || !values.values.length) {
        values.values = [{
          selected: true
        }, {
          selected: false
        }];

        values.values = values.values.map(function (elem, index) {
          elem.label = opts.messages.option + ' ' + (index + 1);
          elem.value = _helpers.hyphenCase(elem.label);
          return elem;
        });
      }

      var field = '';

      field += advFields(values);
      field += '<div class="form-group field-options ' + ClassDisabled(values.type) + '">';
      field += '<label class="false-label">' + opts.messages.selectOptions + '</label>';
      field += '<ol class="sortable-options" style="width:81.33333333%">';
      field += '';
      var labElements = opts.messages.elementsGroupElements;
      var valElements = opts.messages.elementsGroupElementsValue;
      /* Valores del select de opciones pata cargar los tipos de elementos para el agrupamiento*/
      var addOption = '<select  class= "option-actions">';
      valElements.forEach(function (element, i) {
        addOption += '<option value="' + valElements[i] + '">' + labElements[i] + '</option>';
      });
      addOption += '</select>';
      addOption += '<a class="add add-opt-parents-elements"><strong>' + opts.messages.addOptionElementsGroup + '</strong></a>';

      field += _helpers.markup('dt', addOption, { className: 'option-actions head-element ' }).outerHTML
      field += '';
      var type = '';

      //=]
      if (values.elements !== undefined) {
        values.elements = JSON.parse(values.elements);
        for (i = 0; i < values.elements.length; i++) {
          var type = values.elements[i].type.trim();
          var value = values.elements[i].value;
          var label = values.elements[i].label;
          field += '<li id="consect-' + (i + 1) + '"">';
          // elementos primer array o nodo de arrays
          var elementType = "";
          valElements.forEach(function (element, i) {
            if (valElements[i] === type) {
              elementType = labElements[i];
            }

          });

          if (type === 'select' || type === 'checkbox-group') {
            var isNew = false;
            field += selectNewElements(isNew, (i + 1), elementType, value, label, type);
          } else if (type === 'text' || type === 'textarea') {
            field += inputNewElements((i + 1), elementType, value, label, type);
          }


          if (values.elements[i].options !== undefined) {

            var lengthsub = (values.elements[i].options.length - 1);
            //---------------------------------------
            if (lengthsub >= 1) {

              for (var j = 0; j <= lengthsub; j++) {
                var optionValue = values.elements[i].options[j].value;
                var optionLabel = values.elements[i].options[j].label;

                field += childProperties(optionValue, optionLabel, (i + 1), (j + 1));
              }
              field += "</ul>";
            }
          }
          field += '</li>';
        }
      }

      field += '</ol>';
      // making the dynamically added option fields sortable.
      field += '</div>';
      var items = $('.sortable-options').find('li').map(function () {
        var item = {};

        item.id = this.value;
        item.name = $(this).text();

        return item;
      });
      // alert ('values ----> '+JSON.stringify(values.values));
      appendFieldLi(opts.messages.select, field, values);

      $('.sortable-options').sortable(); // making the dynamically added option fields sortable.
    };

    var appendNewField = function appendNewField(values) {
      console.log("appendNewField fn ====> ", values);
      // TODO: refactor to move functions into this object
      var appendFieldType = {
        'select': appendSelectList,
        'selectnested': appendSelectListNested,
        'selectWSnested': appendSelectListWSNested,
        'elementsGroup': appendElementsGroup,
        'rich-text': appendTextarea,
        'textarea': appendTextarea,
        //'radio-group': appendSelectList,
        'plantilla': appendLayer,
        'checkbox-group': appendSelectList
      };

      values = values || '';

      if (appendFieldType[values.type]) {
        appendFieldType[values.type](values);
      } else {
        appendInput(values);
      }

    };

    /**
    * Build the editable properties for the field
    * @param  {object} values configuration object for advanced fields
    * @return {String}        markup for advanced fields
    */
    var advFields = function advFields(values) {
      console.log('values ---->' + values.type);
      var classActive = ClassDisabled(values.type);

      var advFields = [],
        key,
        roles = values.role !== undefined ? values.role.split(',') : [];


      values.size = values.size || 'm';
      values.style = values.style || 'default';

      if (values.type !== 'hr') {
        if (values.type === 'selectnested') {
          advFields.push(textAttribute('labelnested', values, classActive));
        } else {
          advFields.push(textAttribute('label', values, ""));
        }

        advFields.push(textAttribute('name', values, classActive));
      }
      if (values.type === 'header') {
        advFields.push(elementType(values.type, values.element, classActive));

      }
      if (values.type === 'selectnested') {
        // select para segundo label
        advFields.push(selectLabel('Etiqueta 2', values.etiqueta2, classActive));

      }
      if (values.type !== 'button') {
        advFields.push(textoapoyo('Subtexto', values.textoapoyo, ""));
      }
      advFields.push(fieldDescription(values, classActive));
      if (values.type !== 'hr') {

        // Placeholder
        advFields.push(textAttribute('placeholder', values, ""));
      }
      if (values.type === 'header') {
        advFields.push(typeHeader(values.type, values.subtype, classActive));

      }


      if (values.type === "text") {
        //advFields.push(subTypeField(values.type, values.subtype));
        //advFields.push(subTypeField(values));
      }

      advFields.push(btnStyles(values.style, values.type, classActive));

      if (values.type === 'text' || values.type === 'textarea'){
        advFields.push(regexField(values.regextype));
      }

      if (values.type === 'date') {
        advFields.push(startDate("Rango inicial", values.startdate, classActive));
        advFields.push(finalDate("Rango final", values.finaldate, classActive));
      }
      if (values.type === 'button') {
        advFields.push(wsUrlResult("Url envío", values.type, values.urlbutton, classActive));
        advFields.push(redirect("Redirección", values.redirect, classActive));
      }


      //advFields.push(numberGroup("Grupo", values.number_group));
      if (values.type === 'checkbox-group') {
        var nOpciones = values.values.length;
        if (values.values.length <= 2) {
          //   nOpciones = 10;
        }
        /*Segunda version*/
        nOpciones = nOpciones + 10;
        advFields.push(nseleccionados("Núm. de opciones", values.nseleccionados, nOpciones, classActive));
        advFields.push(importancia("Consecutivo", values.importancia, classActive));
      }
      if (values.type === 'selectws') {
        // select para webservices
        advFields.push(wslist(values, classActive));
        //advFields.push(responseDisplay('Display',values.type));
        //advFields.push(responseValue('value',values.type));
      }
      // longitud maxima
      advFields.push(textAttribute('maxlength', values, classActive));
      // Classe
      advFields.push(textAttribute('className', values, classActive));


      /* advFields.push('<div class="form-group access-wrap"><label>' + opts.messages.roles + '</label>');

       advFields.push('<input type="checkbox" name="enable_roles" value="" ' + (values.role !== undefined ? 'checked' : '') + ' id="enable_roles-' + lastID + '"/> <label for="enable_roles-' + lastID + '" class="roles-label">' + opts.messages.limitRole + '</label>');
       advFields.push('<div class="available-roles" ' + (values.role !== undefined ? 'style="display:block"' : '') + '>');

       for (key in opts.roles) {
         if ($.inArray(key, ['date', '4']) === -1) {
           advFields.push('<input type="checkbox" name="roles[]" value="' + key + '" id="fld-' + lastID + '-roles-' + key + '" ' + ($.inArray(key, roles) !== -1 ? 'checked' : '') + ' class="roles-field" /><label for="fld-' + lastID + '-roles-' + key + '">' + opts.roles[key] + '</label><br/>');
         }
       }
       advFields.push('</div></div>');

 if (values.type === 'checkbox-group' || values.type === 'radio-group') {
         advFields.push('<div class="form-group other-wrap"><label>' + opts.messages.enableOther + '</label>');
         advFields.push('<input type="checkbox" name="enable-other" value="" ' + (values.other !== undefined ? 'checked' : '') + ' id="enable-other-' + lastID + '"/> <label for="enable-other-' + lastID + '" class="other-label">' + opts.messages.enableOtherMsg + '</label></div>');
       }
       */


      return advFields.join('');
    };

    /**
    * Description meta for field
    *
    * @param  {Object} values field values
    * @return {String}        markup for attribute, @todo change to actual Node
    */
    var fieldDescription = function fieldDescription(values, classDisabled) {
      var noDescFields = ['header', 'msgFinal', 'footer', 'hr', 'paragraph', 'button'],
        noMakeAttr = [],
        descriptionField = '';

      noDescFields = noDescFields.concat(opts.messages.subtypes.header, opts.messages.subtypes.paragraph, opts.messages.subtypes.hr);

      if (noDescFields.indexOf(values.type) === -1) {
        noMakeAttr.push(true);
      }

      if (noMakeAttr.some(function (elem) {
        return elem === true;
      })) {

        var descriptionURI = $('<div/>').html(values.description).text();

        var fieldDescLabel = _helpers.markup('label', opts.messages.description, { 'for': 'description-' + lastID }),
          fieldDescInput = _helpers.markup('input', null, {
            type: 'text',
            className: 'fld-description form-control ' + classDisabled,
            name: 'description',
            id: 'description-' + lastID,
            value: descriptionURI
          }),
          fieldDesc = _helpers.markup('div', [fieldDescLabel, fieldDescInput], {
            'class': 'form-group description-wrap'
          });
        descriptionField = fieldDesc.outerHTML;
      }

      return descriptionField;
    };
    /**
       * Changes a fields type
       *
       * @param  {Object} values
       * @return {String}      markup for type <select> input
       */
    var subTypeField = function subTypeField(values, classDisabled) {
      var subTypes = opts.messages.subtypes,
        type = values.type,
        subtype = values.subtype || '',
        subTypeField = '',
        selected = void 0;

      if (subTypes[type]) {
        var subTypeLabel = '<label>' + opts.messages.subtype + '</label>';
        subTypeField += '<select ' + classDisabled + '  name="subtype" class="fld-subtype form-control ' + classDisabled + '" id="subtype-' + lastID + '">';
        subTypes[type].forEach(function (element) {
          selected = subtype === element ? 'selected' : '';
          subTypeField += '<option value="' + element + '" ' + selected + '>' + element + '</option>';
        });
        subTypeField += '</select>';
        subTypeField = '<div class="form-group subtype-wrap">' + subTypeLabel + ' ' + subTypeField + '</div>';
      }

      return subTypeField;
    };

    var typeHeader = function typeHeader(type, seleccionado, classDisabled) {
      var subTypes = opts.messages.subtypes,
        subType = '';

      if (subTypes[type]) {
        var subTypeLabel = '<label>' + opts.messages.fontsize + '</label>';
        subType += '<select ' + classDisabled + ' name="subtype" class="fld-subtype form-control ' + classDisabled + '" id="subtype-' + lastID + '">';
        subTypes[type].forEach(function (element) {
          var selected = seleccionado === element ? 'selected' : '';

          subType += '<option value="' + element + '" ' + selected + '>' + element + '</option>';
        });
        subType += '</select>';
        subType = '<div class="form-group subtype-wrap">' + subTypeLabel + ' ' + subType + '</div>';
      }
      return subType;
    };
    var elementType = function elementType(type, seleccionado, classDisabled) {
      var subTypes = opts.messages.subtypes,
        subType = '';

      if (subTypes['elementType']) {
        var subTypeLabel = '<label>' + opts.messages.elementType + '</label>';
        subType += '<select ' + classDisabled + ' name="subtype" class="fld-elementType form-control "' + classDisabled + ' id="subtype-' + lastID + '">';
        var elementvalues = subTypes['elementTypeValue'];
        subTypes['elementType'].forEach(function (element, i) {

          var selected = seleccionado === elementvalues[i] ? 'selected' : '';

          subType += '<option value="' + elementvalues[i] + '" ' + selected + '>' + element + '</option>';
        });
        subType += '</select>';
        subType = '<div class="form-group elementType-wrap">' + subTypeLabel + ' ' + subType + '</div>';
      }
      return subType;
    };
    var nseleccionados = function nseleccionados(type, seleccionado, numberOptions, classDisabled) {
      var subTypes = opts.messages.subtypes,
        subType = '';
      if (type) {
        var subTypeLabel = '<label>' + type + '</label>';
        subType += '<select ' + classDisabled + ' name="subtype" class="fld-nseleccionados form-control' + classDisabled + '" id="nseleccionados-' + lastID + '">';
        for (var element = 1; element < numberOptions; element++) {
          var selected = parseInt(seleccionado) === element ? 'selected' : '';
          subType += '<option value="' + element + '" ' + selected + '>' + element + ' Seleccionados</option>';
        }
        subType += '</select>';
        subType = '<div class="form-group nseleccionados-wrap">' + subTypeLabel + ' ' + subType + '</div>';
      }

      return subType;
    };


    var importancia = function importancia(type, seleccionado, classDisabled) {
      var regexs = opts.messages.subtypes,
        regex = '';

      if (regexs['importancia']) {
        var regexLabel = '<label>' + opts.messages.importancia + '</label>';
        regex += '<select ' + classDisabled + ' name="regex" class="fld-importancia form-control' + classDisabled + '" id="regex-' + lastID + '">';
        regexs['importancia'].forEach(function (element) {
          var selected = seleccionado === element ? 'selected' : '';
          // alert("Seleccionado : "+ selected);
          regex += '<option value="' + element + '" ' + selected + '>' + element + '</option>';
        });
        regex += '</select>';
        regex = '<div class="form-group importancia-wrap">' + regexLabel + ' ' + regex + '</div>';
      }

      return regex;
    };

    var wslist = function wslist(values, classDisabled) {
      Consecutivo++;

      var regexs = opts.messages.subtypes,
        wslist = '';
      Ember.$.ajax({
        url: localStorage.href + '/wss',
        type: 'GET',
        dataType: 'json',
        async: false
      }).done(function (jsonObject) {
        //   alert (JSON.stringify(jsonObject.formulario.estado));
        formAttributes2 = JSON.stringify(jsonObject);
        if (jsonObject) {

          var wsLabel = '<label>' + 'Web  services' + '</label>';
          wslist += '<select ' + classDisabled + ' name="wslist" class="fld-wslist form-control ' + classDisabled + '" id="regex-">';
          var data = [];
          data = JSON.parse(formAttributes2);
          Ember.$.each(data.wss, function (i, item) {
            //
            var selected = data.wss[i].nombre === values.wsname ? 'selected' : '';
            var url = _helpers.base64encode(data.wss[i].url);
            //var url = data.wss[i].url;
            wslist += '<option value="' + data.wss[i].nombre + '|' + Consecutivo + '|' + data.wss[i].id + "|" + url + "|" + data.wss[i].filtro + '" ' + selected + '>' + data.wss[i].nombre + '</option>';
          })
          wslist += '</select>';
          wslist = '<div class="form-group wslist-wrap">' + wsLabel + ' ' + wslist + '</div>';
          //  recorrerArbol(formAttributes2);

        }

        else {
          return "[]";
        }
      })
        .fail(function () {
          return "[]";
        });

      return wslist;
    };

    var wsUrlResult = function wsUrlResult(label, seleccionado, value, classDisabled) {
      Consecutivo++;

      var regexs = opts.messages.subtypes,
        wslist = '';
      Ember.$.ajax({
        url: localStorage.href + '/urls',
        type: 'GET',
        dataType: 'json',
        async: false
      }).done(function (jsonObject) {
        //   alert (JSON.stringify(jsonObject.formulario.estado));
        formAttributes2 = JSON.stringify(jsonObject);
        if (jsonObject) {

          var wsLabel = '<label>' + label + '</label>';
          wslist += '<select ' + classDisabled + ' name="wslist" class="fld-wsUrlResult form-control ' + classDisabled + '" id="regex-">';
          var data = [];
          data = JSON.parse(formAttributes2);

          Ember.$.each(data.urls, function (i, item) {
            //
            var selected = data.urls[i].url === value ? 'selected' : '';
            wslist += '<option value="' + Consecutivo + '|' + data.urls[i].id + "|" + data.urls[i].url + '" ' + selected + '>' + data.urls[i].nombre + '</option>';
          })
          wslist += '</select>';
          wslist = '<div class="form-group wslist-wrap">' + wsLabel + ' ' + wslist + '</div>';
          //  recorrerArbol(formAttributes2);

        }

        else {
          return "[]";
        }
      })
        .fail(function () {
          return "[]";
        });

      return wslist;
    };
    /*

    var urlButton = function urlButton(label, value) {
      if (value === undefined){
        value = "";
      }
      var url = '';
      var urlLabel = '<label>'+ label + '</label>';
      url += '<input type= "text" name="text" class="fld-urlButton form-control" value ="'+value+'" >';
      url += '</input>';
      url = '<div class="form-group date-wrap">' + urlLabel + ' ' + url + '</div>';

      return url;
    };
    */
    var redirect = function redirect(label, value, classDisabled) {
      if (value === undefined) {
        value = "";
      }

      var url = '';
      var urlLabel = '<label>' + label + '</label>';
      url += '<input ' + classDisabled + ' type= "text" name="text" class="fld-redirect form-control ' + classDisabled + '" value ="' + value + '" >';
      url += '</input>';
      url += '<label>&nbsp</label>';
      url += '<div><span style= "padding-left: 20px;"> Por seguridad, la liga que utilices debe comenzar por https, y no por http.</span></div>'
      url = '<div class="form-group date-wrap">' + urlLabel + ' ' + url + '</div>';

      return url;
    };

    var numberStepper = function numberStepper(type, value, classDisabled) {
      var steppers = opts.messages.Steppers,
        stepper = '';

      if (steppers['values']) {
        var stepperLabel = '<label>' + opts.messages.numStepper + '</label>';
        stepper += '<select  ' + classDisabled + ' name="Steppers" class="fld-numberStepper form-control ' + classDisabled + '" id="regex-' + lastID + '">';
        steppers['values'].forEach(function (element, i) {
          var selected = (value - 1) === (i) ? 'selected' : '';
          // alert("Seleccionado : "+ selected);
          stepper += '<option value="' + (i + 1) + '" ' + selected + '>' + element + '</option>';
        });
        stepper += '</select>';
        stepper = '<div class="form-group regex-wrap">' + stepperLabel + ' ' + stepper + '</div>';
      }

      return stepper;
    };
    var numberGroup = function numberGroup(type, value, classDisabled) {
      var groups = opts.messages.groups,
        group = '';

      if (groups['values']) {
        var groupLabel = '<label>' + opts.messages.numGroup + '</label>';
        group += '<select  ' + classDisabled + ' name="groups" class="fld-numberGroup form-control ' + classDisabled + '" id="regex-' + lastID + '">';
        groups['values'].forEach(function (element, i) {
          var selected = (parseInt(value)) === (i) ? 'selected' : '';
          // alert("Seleccionado : "+ selected);
          group += '<option value="' + (i) + '" ' + selected + '>' + element + '</option>';
        });
        group += '</select>';
        group = '<div class="form-group regex-wrap">' + groupLabel + ' ' + group + '</div>';
      }

      return group;
    };

    var datosAbiertos = function datosAbiertos(values) {
      var noRequire = ['header', 'msgFinal', 'footer', 'hr', 'paragraph', 'button'],
        noMake = [],
        datosAbiertos = '';

      if (_helpers.inArray(values.type, noRequire)) {
        noMake.push(true);
      }

      if (!noMake.some(function (elem) {
        return elem === true;
      })) {

        datosAbiertos += '<div  class=" required-wrap form-checks">';
        datosAbiertos += '<label>&nbsp;</label>';
        var datosA = _helpers.markup('input', null, {
          className: 'fld-datosAbiertos float-left ',
          type: 'checkbox',
          name: 'datos-' + lastID,
          id: 'datos-' + lastID,
          value: 1
        });
        val_req = false;
        if (values.dato_abierto === "true") {
          val_req = true;
        }

        datosA.defaultChecked = val_req === true;

        datosAbiertos += datosA.outerHTML;
        datosAbiertos += _helpers.markup('label', opts.messages.dato_abierto, {
          className: 'datos-label',
          'for': 'datos-' + lastID
        }).outerHTML;
        datosAbiertos += '</div>';
      }
      return datosAbiertos;
    };

    var regexField = function regexField(type, classDisabled) {
      var regexs = opts.messages.regexs,
        regex = '';

      if (regexs['values']) {
        var regexLabel = '<label>' + opts.messages.regex + '</label>';
        regex += '<select ' + classDisabled + ' name="regex" class="fld-regex form-control ' + classDisabled + '" id="regex-' + lastID + '">';
        regexs['values'].forEach(function (element) {
          var selected = type === element ? 'selected' : '';
          // alert("Seleccionado : "+ selected);
          regex += '<option value="' + element + '" ' + selected + '>' + element + '</option>';
        });
        regex += '</select>';
        regex = '<div class="form-group regex-wrap">' + regexLabel + ' ' + regex + '</div>';
      }
      textoapoyo
      return regex;
    };
    var textoapoyo = function textoapoyo(label, value, classDisabled) {
      if (value === undefined) { value = ""; }

      var textoapoyo = '';
      var textoapoyoLabel = '<label>' + label + '</label>';
      textoapoyo += '<input ' + classDisabled + ' type= "text" name="text" class="fld-textoapoyo form-control ' + classDisabled + '"  id="textoapoyo-' + lastID + '" value ="' + value + '" >';
      textoapoyo += '</input>';
      textoapoyo = '<div class="form-group textoapoyo-wrap">' + textoapoyoLabel + ' ' + textoapoyo + '</div>';

      return textoapoyo;
    };
    var startDate = function startDate(label, value, classDisabled) {
      var n = 1900;

      if (value === undefined) { value = 2017; }
      var date = '';
      var dateLabel = '<label>' + label + '</label>';
      date += '<select ' + classDisabled + ' name="regex" class="fld-startDate form-control ' + classDisabled + '" id="regex-' + lastID + '">';
      while (n < 2050) {
        var selected = parseInt(value) === n ? 'selected' : '';
        date += '<option value="' + n + '" ' + selected + '>' + n + '</option>';
        n++;
      }
      date += '<select>';
      date = '<div class="form-group date-wrap">' + dateLabel + ' ' + date + '</div>';
      $("#datepicker-" + lastID).datepicker({ dateFormat: "yyyy-mm-dd" });
      return date;
    };
    var labelSelect = function labelSelect(label, value, classDisabled) {
      var date = '';
      var dateLabel = '<label>' + label + '</label>';
      date += '<input ' + classDisabled + ' type= "date" name="date" class="fld-labelSelect form-control ' + classDisabled + '" value ="' + value + '" >';
      date += '</input>';
      date = '<div class="form-group date-wrap">' + dateLabel + ' ' + date + '</div>';

      return date;
    };

    var finalDate = function finalDate(label, value, classDisabled) {
      var n = 1900;

      if (value === undefined) { value = 2017; }
      var date = '';
      var dateLabel = '<label>' + label + '</label>';
      date += '<select  ' + classDisabled + ' name="regex" class="fld-finalDate form-control ' + classDisabled + '" id="regex-' + lastID + '">';
      while (n < 2050) {
        var selected = parseInt(value) === n ? 'selected' : '';
        date += '<option value="' + n + '" ' + selected + '>' + n + '</option>';
        n++;
      }
      date += '<select>';
      date = '<div class="form-group date-wrap">' + dateLabel + ' ' + date + '</div>';

      return date;
    };

    var selectLabel = function selectLabel(label, value, classDisabled) {
      if (value === undefined) {
        value = "";
      }
      var selects = '';
      var selectLabel = '<label>' + label + '</label>';
      selects += '<input ' + classDisabled + ' type= "text" name="text" class="fld-selectLabel form-control ' + classDisabled + '" value ="' + value + '" >';
      selects += '</input>';
      selects = '<div class="form-group date-wrap">' + selectLabel + ' ' + selects + '</div>';

      return selects;
    };

    var btnStyles = function btnStyles(style, type) {
      var tags = {
        button: 'btn '
      },
        styles = false/*opts.messages.styles[tags[type]]*/,
        styleField = '';

      if (styles) {
        var styleLabel = '<label>' + opts.messages.style + '</label>';
        styleField += '<input value="' + style + '" name="style" type="hidden" class="btn-style btn-action">';
        styleField += '<div class="btn-group" role="group">';

        Object.keys(opts.messages.styles[tags[type]]).forEach(function (element) {
          var active = style === element ? 'active' : '';
          //  styleField += '<button value="' + element + '" type="' + type + '" class="' + active + ' btn-xs ' + tags[type] + ' ' + tags[type] + '-' + element + '">' + opts.messages.styles[tags[type]][element] + '</button>';
        });

        styleField += '</div>';

        styleField = '<div class="form-group style-wrap">' + styleLabel + ' ' + styleField + '</div>';
      }

      return styleField;
    };
    var responseDisplay = function responseDisplay(label, value, classDisabled) {
      if (value === undefined) { value = ""; }
      var html = '';
      var textoapoyoLabel = '<label>' + label + '</label>';
      html += '<input ' + classDisabled + ' type= "text" name="text" class="fld-responseDisplay form-control ' + classDisabled + '"  id="datestart-' + lastID + '" value ="' + value + '" >';
      html += '</input>';
      html = '<div class="form-group responseDisplay-wrap">' + textoapoyoLabel + ' ' + html + '</div>';

      return html;
    };
    var responseValue = function responseValue(label, value, classDisabled) {
      if (value === undefined) { value = ""; }
      var html = '';
      var textoapoyoLabel = '<label>' + label + '</label>';
      html += '<input ' + classDisabled + ' type= "text" name="text" class="fld-responseValue form-control ' + classDisabled + '"  id="datestart-' + lastID + '" value ="' + value + '" >';
      html += '</input>'
      html = '<div class="form-group responseValue-wrap">' + textoapoyoLabel + ' ' + html + '</div>';

      return html;
    };
    /**
    * Generate some text inputs for field attributes, **will be replaced**
    * @param  {String} attribute
    * @param  {Object} values
    * @return {String}
    */
    var
      textAttribute = function textAttribute(attribute, values, classDisabled) {

        var noEdit = ['label', 'subtext'];

        var placeholderFields = ['text', 'textarea', 'select'];
        var noName = [, 'hr'];

        var textArea = ['paragraph'];

        var noMaxlength = ['checkbox', 'elementsGroup', 'select', 'hr', 'msgFinal', 'selectws', 'selectnested', 'selectWSnested', 'checkbox-group', 'date', 'autocomplete', 'radio-group', 'hidden', 'button', 'header', 'msgFinal'];
        if (attribute === 'labelnested') {
          attribute = 'label';
          vallabel = 'labelnested';
        } else {
          vallabel = attribute;
        }

        var attrVal = attribute === 'label' ? values.label : values[attribute] || '';
        var attrLabel = opts.messages[vallabel];
        if (attribute === 'label' && _helpers.inArray(values.type, textArea)) {
          attrLabel = opts.messages.content;
        }

        noName = noName.concat(opts.messages.subtypes.header, textArea);
        noMaxlength = noMaxlength.concat(textArea);

        var placeholders = opts.messages.placeholders,
          placeholder = placeholders[attribute] || '',
          attributefield = '',
          noMakeAttr = [];

        // Field has placeholder attribute
        if (attribute === 'placeholder' && !_helpers.inArray(values.type, placeholderFields)) {
          noMakeAttr.push(true);
        }

        if (attrVal === "" && attribute === 'className') {
          if (values.class !== undefined) {
            var result = values.class.replace('form-group col-md-7', '');
            attrVal = result;
          }

        }

        // Field has name attribute
        if (attribute === 'name' && _helpers.inArray(values.type, noName)) {
          noMakeAttr.push(true);
        }

        // Field has maxlength attribute
        if (attribute === 'maxlength' && _helpers.inArray(values.type, noMaxlength)) {
          noMakeAttr.push(true);
        }

        if (!noMakeAttr.some(function (elem) {
          return elem === true;
        })) {
          var attributeLabel = '<label>' + attrLabel + '</label>';

          if (attribute === 'label' && _helpers.inArray(values.type, textArea)) {
            attributefield += '<textarea ' + classDisabled + ' name="' + attribute + '" placeholder="' + placeholder + '" class="fld-' + attribute + ' form-control ' + classDisabled + '" id="' + attribute + '-' + lastID + '">' + attrVal + '</textarea>';
          } else {
            attributefield += '<input ' + classDisabled + '   type="text" value="' + attrVal + '" name="' + attribute + '" placeholder="' + placeholder + '" class="fld-' + attribute + ' form-control ' + classDisabled + '" id="' + attribute + '-' + lastID + '">';
          }

          attributefield = '<div class="form-group ' + attribute + '-wrap">' + attributeLabel + ' ' + attributefield + '</div>';
        }

        return attributefield;
      };
    //
    var requiredField = function requiredField(values) {
      var noRequire = ['header', 'msgFinal', 'footer', 'hr', 'paragraph', 'button'],
        noMake = [],
        requireFeild = '';

      if (_helpers.inArray(values.type, noRequire)) {
        noMake.push(true);
      }

      if (!noMake.some(function (elem) {
        return elem === true;
      })) {

        requireFeild += '<div class="required-wrap form-checks">';
        requireFeild += '<label class="espacio" >&nbsp;</label>';
        var _requiredField = _helpers.markup('input', null, {
          className: 'required',
          type: 'checkbox',
          name: 'required-' + lastID,
          id: 'required-' + lastID,
          value: 1
        });
        val_req = false;
        if (values.required === "true") {
          val_req = true;
        }

        _requiredField.defaultChecked = val_req === true;

        requireFeild += _requiredField.outerHTML;
        requireFeild += _helpers.markup('label', opts.messages.required, {
          className: 'required-label',
          'for': 'required-' + lastID
        }).outerHTML;
        requireFeild += '</div>';
      }
      return requireFeild;
    };

    // Append the new field to the editor
    var appendFieldLi = function appendFieldLi(title, field, values) {
      var labelVal = $(field).find('input[name="label"]').val(),
        label = labelVal ? labelVal : title;

      var delBtn = _helpers.markup('a', opts.messages.remove, {
        id: 'del_' + lastID,
        className: 'del-button btn delete-confirm',
        title: opts.messages.removeMessage
      }),
        toggleBtn = _helpers.markup('a', null, {
          id: lastID + '-edit',
          className: 'toggle-form btn fa fa-cog',
          title: "Editar",

        }),
        required = values.required,
        toggle = values.toggle || undefined,
        tooltip = values.description !== '' && values.description !== undefined ? '<span class="tooltip-element" tooltip="' + values.description + '">?</span>' : '';
      if (parseInt(localStorage.getItem('statusForm')) != 3) {
        var liContents = _helpers.markup('div', [toggleBtn, delBtn], { className: 'field-actions' }).outerHTML;
      }
      else {
        var liContents = _helpers.markup('div', [toggleBtn], { className: 'field-actions' }).outerHTML;
      }
      values.description !== ''
      liContents += '<label class="field-label">' + label + '</label>' + tooltip + '<span class="required-asterisk" ' + (required === 'true' ? 'style="display:inline"' : '') + '> *</span>';
      liContents += _helpers.markup('div', '', { className: 'prev-holder' }).outerHTML;
      //liContents += '<div id="frm-' + lastID + '-fld" class="frm-holder">';
      liContents += '<div id="' + lastID + '-holder" class="frm-holder">';
      liContents += '<div class="form-elements">';

      liContents += requiredField(values);
      if (values.type !== 'hr' && values.type !== "header") {
        liContents += datosAbiertos(values);
      }
      if (values.type === 'checkbox') {
        liContents += '<div class="form-group">';
        liContents += '<label>&nbsp;</label>';
        liContents += '<input class="checkbox-toggle" type="checkbox" value="1" name="toggle-' + lastID + '" id="toggle-' + lastID + '"' + (toggle === 'true' ? ' checked' : '') + ' /><label class="toggle-label" for="toggle-' + lastID + '">' + opts.messages.toggle + '</label>';
        liContents += '</div>';
      }
      liContents += field;
      liContents += _helpers.markup('a', opts.messages.close, { className: 'close-field' }).outerHTML;

      liContents += '</div>';
      liContents += '</div>';

      var li = _helpers.markup('li', liContents, {
        'class': values.type + '-field form-field',
        'type': values.type,
        //id: 'frm-' + lastID + '-item'
        id: lastID
      }),
        $li = $(li);

      $li.data('fieldData', { attrs: values });

      if (typeof _helpers.stopIndex !== 'undefined') {
        $('> li', $sortableFields).eq(_helpers.stopIndex).after($li);
      } else {
        $sortableFields.append($li);
      }

      _helpers.updatePreview($li);

      $(document.getElementById('frm-' + lastID + '-item')).hide().slideDown(250);
      lastID = _helpers.incrementId(lastID);
      //lastID++;
    };

    // Select field html, since there may be multiple
    var selectFieldOptions = function selectFieldOptions(name, values, selected, multipleSelect) {
      var optionInputType = {
        selected: multipleSelect ? 'checkbox' : 'radio'
      };
      var classDisplay = "";
      var defaultOptionData = {
        selected: selected,
        label: '',
        value: ''
      };

      var optionData = Object.assign(defaultOptionData, values),
        optionInputs = [];

      for (var prop in optionData) {
        var valor_propiedad = "";
        if (optionData.hasOwnProperty(prop)) {
          if (prop === 'value') {

            valor_propiedad = "return valida(event)";
          }

          if (prop == 'dato_abierto') {
            classDisplay = ClassDisplayField();
          }
          var attrs = {
            type: optionInputType[prop] || 'text',
            'class': 'option-' + prop+ " "+ classDisplay,
            onkeypress: valor_propiedad,
            placeholder: opts.messages.placeholders[prop],
            value: optionData[prop],
            name: name
          };
          if (prop !== 'position' && prop !== "id") {
            var option = _helpers.markup('input', null, attrs);
          }

          if (prop === 'selected') {
            option.checked = optionData.selected;
          }
          optionInputs.push(option);
        }
      }

      var removeAttrs = {
        className: 'remove btn',
        title: opts.messages.removeMessage
      };
      optionInputs.push(_helpers.markup('a', opts.messages.remove, removeAttrs));
      var field = _helpers.markup('li', optionInputs);

      return field.outerHTML;
    };
    // Select field html, since there may be multiple
    var selectFieldOptionsNested = function selectFieldOptionsNested(name, values, selected, multipleSelect) {
      var optionInputType = {
        selected: multipleSelect ? 'checkbox' : 'radio'
      };
      var classDisabled = "";
      var defaultOptionData = {
        selected: selected,
        label: '',
        value: ''
      };

      var optionData = Object.assign(defaultOptionData, values),
        optionInputs = [];

      for (var prop in optionData) {
        if (optionData.hasOwnProperty(prop)) {

          var attrs = {
            type: optionInputType[prop] || 'text',
            'class': 'option-' + prop + ' ' + classDisabled,
            placeholder: opts.messages.placeholders[prop],
            value: optionData[prop],
            name: name
          };
          var option = _helpers.markup('input', null, attrs);
          if (prop === 'selected') {
            option.checked = optionData.selected;
          }
          optionInputs.push(option);
        }
      }

      var removeAttrs = {
        className: 'remove btn',
        title: opts.messages.removeMessage
      };
      optionInpu
      ts.push(_helpers.markup('a', opts.messages.remove, removeAttrs));

      var field = _helpers.markup('li', optionInputs);
      _helpers.updatePreview($field);
      _helpers.save();
      return field.outerHTML;
    };


    // ---------------------- UTILITIES ---------------------- //

    // delete options
    $sortableFields.on('click touchstart', '.remove', function (e) {
      var $field = $(this).parents('.form-field:eq(0)');
      e.preventDefault();
      var optionsCount = $(this).parents('.sortable-options:eq(0)').children('li').length;
      if (optionsCount <= 2) {
        opts.notify.error('Error: ' + opts.messages.minOptionMessage);
      } else {
        $(this).parent('li').slideUp('250', function () {
          $(this).remove();
          _helpers.updatePreview($field);
          _helpers.save();
        });
      }
    });

    // touch focus
    $sortableFields.on('touchstart', 'input', function (e) {
      if (e.handled !== true) {
        if ($(this).attr('type') === 'checkbox') {
          $(this).trigger('click');
        } else {
          $(this).focus();
          var fieldVal = $(this).val();
          $(this).val(fieldVal);
        }
      } else {
        return false;
      }
    });

    // toggle fields
    $sortableFields.on('click touchstart', '.toggle-form, .close-field', function (e) {
      e.stopPropagation();
      e.preventDefault();
      _helpers.updatePreview($field);
      _helpers.save();
      if (e.handled !== true) {
        var targetID = $(this).parents('.form-field:eq(0)').attr('id');
        _helpers.toggleEdit(targetID);
        e.handled = true;
      } else {
        return false;
      }
    });

    /**
    * Toggles the edit mode for the given field
    * @param  {String} fieldId
    */
    _helpers.toggleEdit = function (fieldId) {
      var field = document.getElementById(fieldId),
        toggleBtn = $('.toggle-form', field),
        editMode = $('.frm-holder', field);
      field.classList.toggle('editing');
      toggleBtn.toggleClass('open');
      $('.prev-holder', field).slideToggle(250);
      editMode.slideToggle(250);
    };

    // update preview to label
    $sortableFields.on('keyup change', '[name="label"]', function () {
      $('.field-label', $(this).closest('li')).text($(this).val());
    });

    // remove error styling when users tries to correct mistake
    $sortableFields.delegate('input.error', 'keyup', function () {
      $(this).removeClass('error');
    });

    // update preview for description
    $sortableFields.on('keyup', 'input[name="description"]', function () {
      var $field = $(this).parents('.form-field:eq(0)');
      var closestToolTip = $('.tooltip-element', $field);
      var ttVal = $(this).val();
      if (ttVal !== '') {
        if (!closestToolTip.length) {
          var tt = '<span class="tooltip-element" tooltip="' + ttVal + '">?</span>';
          $('.field-label', $field).after(tt);
        } else {
          closestToolTip.attr('tooltip', ttVal).css('display', 'inline-block');
        }
      } else {
        if (closestToolTip.length) {
          closestToolTip.css('display', 'none');
        }
      }
    });

    _helpers.updateMultipleSelect();

    // format name attribute
    $sortableFields.delegate('input[name="name"]', 'blur', function () {
      $(this).val(_helpers.safename($(this).val()));
      if ($(this).val() === '') {


        $(this).addClass('field_error').attr('placeholder', opts.messages.cannotBeEmpty);
      } else {
        $(this).removeClass('field_error');
      }
    });

    $sortableFields.delegate('input.fld-maxlength', 'blur', function () {
      $(this).val(_helpers.forceNumber($(this).val()));
    });

    // Delete field
    $sortableFields.on('click touchstart', '.delete-confirm', function (e) {
      e.preventDefault();

      var buttonPosition = this.getBoundingClientRect(),
        bodyRect = document.body.getBoundingClientRect(),
        coords = {
          pageX: buttonPosition.left + buttonPosition.width / 2,
          pageY: buttonPosition.top - bodyRect.top - 12
        };

      //var deleteID = $(this).attr('id').replace(/del_/, ''),
      //$field = $(document.getElementById('frm-' + deleteID + '-item'));
      var deleteID = $(this).parents('.form-field:eq(0)').attr('id'),
        $field = $(document.getElementById(deleteID));


      var removeField = function removeField() {
        $field.slideUp(250, function () {
          $field.removeClass('deleting');
          $field.remove();
          _helpers.save();
          if (!$sortableFields[0].childNodes.length) {
            $stageWrap.addClass('empty').attr('data-content', opts.messages.getStarted);
          }
        });
      };


      document.addEventListener('modalClosed', function () {
        $field.removeClass('deleting');
      }, false);

      // Check if user is sure they want to remove the field
      if (opts.fieldRemoveWarn) {
        var warnH3 = _helpers.markup('h3', opts.messages.warning),
          warnMessage = _helpers.markup('p', opts.messages.fieldRemoveWarning);
        _helpers.confirm([warnH3, warnMessage], removeField, coords);
        $field.addClass('deleting');
      } else {
        removeField($field);
      }
    });

    // Update button style selection
    $sortableFields.on('click', '.style-wrap button', function () {
      var styleVal = $(this).val(),
        $parent = $(this).parent(),
        $btnStyle = $parent.prev('.btn-style');
      $btnStyle.val(styleVal);
      $(this).siblings('.btn').removeClass('active');
      $(this).addClass('active');
      saveAndUpdate.call($parent);
    });

    // Attach a callback to toggle required asterisk
    $sortableFields.on('click', 'input.required', function () {
      var requiredAsterisk = $(this).parents('li.form-field').find('.required-asterisk');
      requiredAsterisk.toggle();
    });

    // Attach a callback to toggle roles visibility
    $sortableFields.on('click', 'input[name="enable_roles"]', function () {
      var roles = $(this).siblings('div.available-roles'),
        enableRolesCB = $(this);
      roles.slideToggle(250, function () {
        if (!enableRolesCB.is(':checked')) {
          $('input[type="checkbox"]', roles).removeAttr('checked');
        }
      });
    });

    // Attach a callback to add new options
    $sortableFields.on('click', '.add-opt', function (e) {
      e.preventDefault();
      var $optionWrap = $(this).parents('.field-options:eq(0)'),
        $multiple = $('[name="multiple"]', $optionWrap),
        $firstOption = $('.option-selected:eq(0)', $optionWrap),
        isMultiple = false;

      if ($multiple.length) {
        isMultiple = $multiple.prop('checked');
      } else {
        isMultiple = $firstOption.attr('type') === 'checkbox';
      }

      var name = $firstOption.attr('name');

      $('.sortable-options', $optionWrap).append(selectFieldOptions(name, false, false, isMultiple));
      _helpers.updateMultipleSelect();
      _helpers.updatePreview($field);
      _helpers.save();
    });


    // Attach a callback to add new options nested parents
    $sortableFields.on('click', '.add-opt-parents', function (e) {
      e.preventDefault();
      var cont = $(this).parent().parent().parent().find('li').length; //$(this).parent().parent().length;
      cont = cont + 1;
      var field = '<li id="consect-' + (cont) + '"">';
      //field += '<input type="radio" class="option-selected" name="select-'+cont +'">';
      field += '<input type="text" id="consectLabel-' + (cont) + '"class="option-label" placeholder="Etiqueta" value="Opción ' + cont + '" name="select-' + cont + '">';
      field += '<input type="text" id="consectValue-' + (cont) + '" class="option-value" placeholder="Valor" value="Opcion-' + cont + '" name="select-' + cont + '"  onkeypress="return valida(event)">';
      field += '<a class="btn_remove del-element " title="Remover Elemento">×</a>';
      field += '<br><br>';
      var addOption = _helpers.markup('a', opts.messages.addOption, { className: 'add add-opt-nested' });
      field += "<ul class = 's'>";
      field += _helpers.markup('ul', addOption, { className: 'option-actions' }).outerHTML;
      field += childProperties(undefined, undefined, cont, 1);
      field += childProperties(undefined, undefined, cont, 2);
      field += "</ul>";

      field += '</li>';
      var contenedor = $(this).parent().parent(); //ID del contenedor
      contenedor.append(field);
      _helpers.updatePreview($field);
      _helpers.save();
      //  _helpers.updateMultipleSelect();
    });

    // Attach a callback to add new options nested parents
    $sortableFields.on('click', '.add-opt-parents-ws', function (e) {
      e.preventDefault();
      var cont = $(this).parent().parent().parent().find('li').length; //$(this).parent().parent().length;
      cont = cont + 1;
      var field = '<li id="consect-' + (cont) + '"">';
      field += '<a class="btn_remove elim-opt-nested del-element" title="Remover Elemento">×</a>';
      field += '<label class="false-label">Etiqueta</label>';
      field += '<input type="text" id="consectLabel-' + cont + '" class="option-label" placeholder="Etiqueta" value="Etiqueta' + cont + '" name="Etiqueta' + cont + '">';
      field += '<br><br>';
      field += '<label class="false-label"> Valor</label>';
      field += '<input type="text" id="consectValue-' + cont + '" class="option-value" placeholder="Valor" value="valor' + cont + '" name="Valor' + cont + '"  onkeypress="return valida(event)">';
      field += '<br><br>';



      var wss = wslist("ws_name");
      field += wss;

      field += '</li>';
      var contenedor = $(this).parent().parent(); //ID del contenedor
      contenedor.append(field);
      _helpers.updatePreview($field);
      _helpers.save();
      //  _helpers.updateMultipleSelect();
    });


    /*elementos agrergados */
    // deacuerdo al combo
    $sortableFields.on('click', '.add-opt-parents-elements', function (e) {
      e.preventDefault();
      /*Valor del select depende si es un select un input se pinta los atributos de estos*/
      var element = $(this).siblings('select').val();

      /* Obtiene la cantidad de elementos de la lista para crear el */
      var cont = $(this).parent().parent().parent().find('li').length;
      var labElements = opts.messages.elementsGroupElements;
      var valElements = opts.messages.elementsGroupElementsValue;
      var field = '<li id="consect-' + (cont) + '"">';
      if (element === valElements[0]) {
        /*Agrega elementos select*/
        field += selectNewElements(true, cont, labElements[0], valElements[0] + cont, labElements[0] + " " + cont, element);
      }
      else if (element === valElements[1]) {
        /* Agrega elementos group*/
        field += selectNewElements(true, cont, labElements[1], valElements[1] + cont, labElements[1] + " " + cont, element);
      }
      else if (element === valElements[2]) {
        /* Agrega elementos input*/
        field += inputNewElements(cont, labElements[2], valElements[2] + cont, labElements[2] + " " + cont, element);
      }
      else if (element === valElements[3]) {
        /* Agrega elementos textarea*/
        field += inputNewElements(cont, labElements[3], valElements[3] + cont, labElements[3] + " " + cont, element);
      }

      field += '</li>';
      var contenedor = $(this).parent().parent(); //ID del contenedor
      contenedor.append(field);
      _helpers.updatePreview($field);
      _helpers.save();

      //  _helpers.updateMultipleSelect();
    });

    var selectNewElements = function selectNewElements(isNew, cont, elementTypeVal, elementValue, elementLabel, elementType) {

      var field = '';
      var classDisabled = ClassDisabled();

      field += '<div class="">' + elementTypeVal + '</div><br><br>';
      field += '<label class="false-label">' + opts.messages.placeholders.label + '</label>';
      field += '<input  ' + classDisabled + ' type="text" id="consectLabel-elements" class="option-label" placeholder="Etiqueta" value=" ' + elementLabel + '">';
      field += '<a  ' + classDisabled + ' class="btn_remove del-element " title="Remover Elemento">×</a>';
      field += '<br><br>';
      field += '<input type="hidden" id="consectType-elements" class="option-type" value=" ' + elementType + '">';
      field += '<label class="false-label">' + opts.messages.placeholders.name + '</label>';
      field += '<input  ' + classDisabled + ' type="text" id="consectValue-elements" class="option-value" placeholder="Valor" value="' + elementValue + '">';
      field += '<ul class = "children-options">';
      var addOption = _helpers.markup('a', opts.messages.addOption, { className: 'add add-opt-elements  ' + classDisabled });
      field += _helpers.markup('dd', addOption, { className: 'option-actions' }).outerHTML;
      // Si es Nuevo se agregan dos opciones por default
      if (isNew) {

        field += childProperties(undefined, undefined, 1, 1);
        field += childProperties(undefined, undefined, 2, 2);

        field += '</ul>'
      }

      return field;
    };


    var inputNewElements = function inputNewElements(cont, elementTypeVal, elementValue, elementLabel, elementType) {

      var field = '';
      field += '<div class="">' + elementTypeVal + '</div><br><br>';
      field += '<label class="false-label">' + opts.messages.placeholders.label + '</label>';
      field += '<input type="text" id="consectLabel-elements"class="option-label" placeholder="Etiqueta" value="' + elementLabel + '">';
      field += '<a class="btn_remove del-element " title="Remover Elemento">×</a>';
      field += '<br><br>';
      field += '<input type="hidden" id="consectType-elements"class="option-type" value=" ' + elementType + '">';
      field += '<label class="false-label">' + opts.messages.placeholders.name + '</label>';
      field += '<input type="text" id="consectValue-elements"class="option-value" placeholder="Valor" value="' + elementValue + '">';
      return field;
    };
    /*Agrega nuevos pero a selects anidados hijos*/


    $sortableFields.on('click', '.add-opt-nested', function (e) {

      var MaxInputs = 100; //Número Maximo de Campos
      var contenedor = $(this).parent().parent(); //ID del contenedor
      var AddButton = $("#agregarCampo"); //ID del Botón Agregar

      //var x = número de campos existentes en el contenedor
      var x = $(this).parent().parent().parent().find('dl').length;
      var fieldParents = $(this).parent().parent().parent().attr("id");
      //  var FieldParents = $(this).parent().parent().find('li').length;

      var res = fieldParents.split("-");
      var fieldCount = x; //para el seguimiento de los campos
      if (x <= MaxInputs) //max input box allowed
      {
        fieldCount++;
        //agregar campo hijo
        //se envia undefined para que coloque los valores de defauld y se envia el numero de padre a crear y el numero de hijos
        field = childProperties(undefined, undefined, res[1], fieldCount);
        $(contenedor).append(field);
        x++; //text box increment
      }
      _helpers.updatePreview($field);
      _helpers.save();

      return false;

    });


    $sortableFields.on('click', '.add-opt-elements', function (e) {

      var MaxInputs = 100; //Número Maximo de Campos
      var contenedor = $(this).parent().parent(); //ID del contenedor
      var AddButton = $("#agregarCampo"); //ID del Botón Agregar

      //var x = número de campos existentes en el contenedor
      var x = $(this).parent().parent().parent().find('dl').length;
      var fieldParents = $(this).parent().parent().parent().attr("id");
      var res = fieldParents.split("-");
      var fieldCount = x; //para el seguimiento de los campos
      if (x <= MaxInputs) //max input box allowed
      {
        fieldCount++;
        //agregar campo hijo
        //se envia undefined para que coloque los valores de defauld y se envia el numero de padre a crear y el numero de hijos
        field = childProperties(undefined, undefined, res[1], fieldCount);
        $(contenedor).append(field);
        x++; //text box increment
      }
      _helpers.updatePreview($field);
      _helpers.save();

      return false;

    });


    $sortableFields.on('click', '.elim-opt-nested', function (e) {//click en eliminar campo

      $(this).parent().remove(); //eliminar el campo
      _helpers.updatePreview($field);
      _helpers.save();
      return false;
    });

    $sortableFields.on('click', '.del-element', function (e) {//click en eliminar campo
      var value = $(this).parent().parent();
      var id_elim = $(this).parent().attr("id");

      // $(this).parent().remove(); //eliminar el campo
      var eliminar = true;
      var flagEliminar = true;
      var idnew = 0;
      value.children('li').each(function (indice, elemento) {
        var id = $(this).attr('id');
        // Verificamos si hay un elemnto con id
        if (id !== undefined) {
          // verificamos si el elemnto a elminar  es igual a que se esta recorriendo
          if (id === id_elim) {
            if (eliminar) {
              if (flagEliminar) {
                $(this).remove();
                flagEliminar = false;
                //eliminar elemento
              }
            }
          } else {
            if (flagEliminar === false) {
              $(this).attr('id', 'consect-' + (indice - 1));
            }
            else {
              $(this).attr('id', 'consect-' + (indice));

            }
          }
        }

      });
      _helpers.updatePreview($field);
      _helpers.save();
      return false;
    });



    $sortableFields.on('mouseover mouseout', '.remove, .del-button', function () {
      $(this).parents('li:eq(0)').toggleClass('delete');
    });

    // View XML
    var xmlButton = $(document.getElementById(frmbID + '-view-data'));
    xmlButton.click(function (e) {
      e.preventDefault();
      var xml = _helpers.htmlEncode(elem.val()),
        code = _helpers.markup('code', xml, { className: 'xml' }),
        pre = _helpers.markup('pre', code);
      _helpers.dialog(pre, null, 'data-dialog');
    });
    // Convert Xml a jSon


    var x2js = new X2JS();

    // Guardar Json
    var generateJSON = $(document.getElementById('generateJSON'));
    generateJSON.visible = false;
    generateJSON.click(function (e) {

      var flag = 0;
      var fields = $('li.form-field');


      if (fields.length) {

        if (flag == 0) {

          e.preventDefault();
          var xml = elem.val();
          var res;
          var json = "";
          var position = 0;

          json = JSON.stringify(x2js.xml_str2json(xml))

          res = json.replace('"form-template":{"fields":{', "");
          res = res.replace('}}', "");
          res = res.replace('field', "atributos");
          res = res.replace(/btn btn-action form-group col-md-7/g, "btn btn-input");

          var jsonRes = JSON.parse(res);
          if (jsonRes !== null) {
            if (jsonRes.atributos.length === undefined) {
              var atts = '[' + JSON.stringify(jsonRes.atributos) + ']';
              jsonRes.atributos = JSON.parse(atts);
            }
          }
          for (var i = 0; i < jsonRes.atributos.length; i++) {
            position = i + 1;
            // limpieza de nodos array  ’
            jsonRes.atributos[i].position = position;
            jsonRes.atributos[i].class = jsonRes.atributos[i]._class;
            jsonRes.atributos[i].response_display = jsonRes.atributos[i]._response_display;
            jsonRes.atributos[i].response_value = jsonRes.atributos[i]._response_value;
            jsonRes.atributos[i].number_stepper = jsonRes.atributos[i]._number_stepper;
            jsonRes.atributos[i].label = jsonRes.atributos[i]._label;
            jsonRes.atributos[i].name = jsonRes.atributos[i]._name;
            jsonRes.atributos[i].required = jsonRes.atributos[i]._required;
            jsonRes.atributos[i].type = jsonRes.atributos[i]._type;
            jsonRes.atributos[i].subtype = jsonRes.atributos[i]._subtype;
            jsonRes.atributos[i].dato_abierto = jsonRes.atributos[i]._dato_abierto;
            jsonRes.atributos[i].textoapoyo = jsonRes.atributos[i]._textoapoyo;
            jsonRes.atributos[i].regextype = jsonRes.atributos[i]._regextype;
            jsonRes.atributos[i].wsList = jsonRes.atributos[i].wslist;
            if (jsonRes.atributos[i].option !== undefined) {
              jsonRes.atributos[i].options = jsonRes.atributos[i].option;
            }
            jsonRes.atributos[i].wsname = jsonRes.atributos[i]._wsname;
            jsonRes.atributos[i].ws_jerarquia = jsonRes.atributos[i]._ws_jerarquia;
            jsonRes.atributos[i].ws_id = jsonRes.atributos[i]._ws_id;
            jsonRes.atributos[i].description = jsonRes.atributos[i]._description;
            jsonRes.atributos[i].placeholder = jsonRes.atributos[i]._placeholder;
            jsonRes.atributos[i].maxlength = jsonRes.atributos[i]._maxlength;
            jsonRes.atributos[i].nseleccionados = jsonRes.atributos[i]._nseleccionados;
            jsonRes.atributos[i].importancia = jsonRes.atributos[i]._importancia;
            jsonRes.atributos[i].redirect = jsonRes.atributos[i]._redirect;
            jsonRes.atributos[i].url_button = jsonRes.atributos[i]._url_button;
            jsonRes.atributos[i].element = jsonRes.atributos[i]._element;
            jsonRes.atributos[i].etiqueta2 = jsonRes.atributos[i]._etiqueta2;
            jsonRes.atributos[i].regex = jsonRes.atributos[i]._regex;
            jsonRes.atributos[i].regex_msg = jsonRes.atributos[i]._regex_msg;
            jsonRes.atributos[i].startdate = jsonRes.atributos[i]._startdate;
            jsonRes.atributos[i].finaldate = jsonRes.atributos[i]._finaldate;

            delete jsonRes.atributos[i]._response_value;
            delete jsonRes.atributos[i]._response_display;
            delete jsonRes.atributos[i]._label;
            delete jsonRes.atributos[i]._class;
            delete jsonRes.atributos[i]._name;
            delete jsonRes.atributos[i]._required;
            delete jsonRes.atributos[i]._type;
            delete jsonRes.atributos[i]._subtype;
            delete jsonRes.atributos[i]._element;
            delete jsonRes.atributos[i]._dato_abierto;
            delete jsonRes.atributos[i]._textoapoyo;
            delete jsonRes.atributos[i]._regextype;
            delete jsonRes.atributos[i].wslist;
            delete jsonRes.atributos[i].option;
            delete jsonRes.atributos[i]._wsname;
            delete jsonRes.atributos[i]._ws_jerarquia;
            delete jsonRes.atributos[i]._ws_id;
            delete jsonRes.atributos[i]._description;
            delete jsonRes.atributos[i]._placeholder;
            delete jsonRes.atributos[i]._maxlength;
            delete jsonRes.atributos[i]._nseleccionados;
            delete jsonRes.atributos[i]._importancia;
            delete jsonRes.atributos[i]._number_stepper;
            delete jsonRes.atributos[i]._startdate;
            delete jsonRes.atributos[i]._finaldate;
            delete jsonRes.atributos[i]._redirect;
            delete jsonRes.atributos[i]._url_button;
            delete jsonRes.atributos[i]._etiqueta2;
            delete jsonRes.atributos[i]._regex;
            // SE VERIFICA QUE Tenga opciones en el caso de que sea select checkbox o radio
            if (jsonRes.atributos[i].options !== undefined) {
              var totalOptions = jsonRes.atributos[i].options.length;
              var sub_options = null;

              if (totalOptions === undefined) {

                if (jsonRes.atributos[i].options.sub_options !== undefined) {
                  sub_options = jsonRes.atributos[i].options.sub_options;
                }


                jsonRes.atributos[i].options.sub_options = null;
                var array1 = jsonRes.atributos[i].options;
                // se  quitan los suboptions para agregarlos despues

                var fuente = JSON.stringify(array1);
                fuente = fuente.replace(/{/g, "[{");

                var resultado = fuente.replace(/}/g, "}]");
                jsonRes.atributos[i].options = null;
                jsonRes.atributos[i].options = JSON.parse(resultado);

                jsonRes.atributos[i].options[0].sub_options = sub_options;
                totalOptions = 1;
              }

              for (var o = 0; o < totalOptions; o++) {
                var idOption = "id_" + position + "_" + (o + 1);
                var selectPosition = +position + "." + (o + 1);

                jsonRes.atributos[i].options[o].dato_abierto = jsonRes.atributos[i].dato_abierto;
                jsonRes.atributos[i].options[o].id = idOption;
                jsonRes.atributos[i].options[o].position = selectPosition;
                jsonRes.atributos[i].options[o].selected = jsonRes.atributos[i].options[o]._selected;
                jsonRes.atributos[i].options[o].value = jsonRes.atributos[i].options[o]._value;
                jsonRes.atributos[i].options[o].ws_url = jsonRes.atributos[i].options[o]._ws_url;
                jsonRes.atributos[i].options[o].response_display = jsonRes.atributos[i].options[o]._response_display;
                jsonRes.atributos[i].options[o].ws_name = jsonRes.atributos[i].options[o]._ws_name;
                jsonRes.atributos[i].options[o].response_value = jsonRes.atributos[i].options[o]._response_value;


                jsonRes.atributos[i].options[o].label = HTML_ENTITIES.encode(jsonRes.atributos[i].options[o].__text);
                jsonRes.atributos[i].options[o].label_principal = jsonRes.atributos[i].options[o]._label_principal;
                delete jsonRes.atributos[i].options[o]._value;
                delete jsonRes.atributos[i].options[o].__text;
                delete jsonRes.atributos[i].options[o]._selected;
                delete jsonRes.atributos[i].options[o]._label_principal;
                delete jsonRes.atributos[i].options[o]._id;
                delete jsonRes.atributos[i].options[o]._ws_url;
                delete jsonRes.atributos[i].options[o]._response_value;
                delete jsonRes.atributos[i].options[o]._response_display;
                delete jsonRes.atributos[i].options[o]._ws_name;
                if (jsonRes.atributos[i].options[o].sub_options != undefined) {
                  var totalsub_options = jsonRes.atributos[i].options[o].sub_options.length;
                  //verificamos que tenga el numero de elementos sino lo reseteamos y agregamos los elementos en array
                  if (totalsub_options === undefined) {
                    var array1 = jsonRes.atributos[i].options[o].sub_options;
                    var fuente = JSON.stringify(array1);
                    fuente = fuente.replace(/{/g, "[{");
                    fuente = fuente.replace(/}/g, "}]");

                    jsonRes.atributos[i].options[o].sub_options = null;
                    jsonRes.atributos[i].options[o].sub_options = JSON.parse(fuente);
                    totalsub_options = 1;
                  }
                  for (var sub = 0; sub < totalsub_options; sub++) {

                    var idSubOption = idOption + "_" + (sub + 1)
                    var positionsub = selectPosition + "" + (sub + 1)
                    jsonRes.atributos[i].options[o].sub_options[sub].dato_abierto = jsonRes.atributos[i].dato_abierto;;
                    jsonRes.atributos[i].options[o].sub_options[sub].id = idSubOption;
                    jsonRes.atributos[i].options[o].sub_options[sub].position = positionsub;
                    jsonRes.atributos[i].options[o].sub_options[sub].value = HTML_ENTITIES.encode(jsonRes.atributos[i].options[o].sub_options[sub]._value);
                    jsonRes.atributos[i].options[o].sub_options[sub].label = HTML_ENTITIES.encode(jsonRes.atributos[i].options[o].sub_options[sub].__text);

                    delete jsonRes.atributos[i].options[o].sub_options[sub]._value;
                    delete jsonRes.atributos[i].options[o].sub_options[sub].__text;
                  }
                }
              }
            }
            // SE VERIFICA QUE Tenga elementos en el caso de agrupado
            else if (jsonRes.atributos[i].elements != undefined) {
              var totalelements = jsonRes.atributos[i].elements.length;
              var options = null;
              if (totalelements === undefined) {
                if (jsonRes.atributos[i].elements.options !== undefined) {
                  options = jsonRes.atributos[i].elements.options;
                }
                // reseteamos las opciones por que ya las tenemos en la variable options de elementos
                jsonRes.atributos[i].elements.options = null;

                if (totalelements === undefined) {
                  var array1 = jsonRes.atributos[i].elements;
                  var fuente = JSON.stringify(array1);
                  fuente = fuente.replace(/{/g, "[{");
                  fuente = fuente.replace(/}/g, "}]");

                  jsonRes.atributos[i].elements = JSON.parse(fuente);

                  jsonRes.atributos[i].elements[0].options = options;
                  totalelements = 1;
                }
              }
              for (var ele = 0; ele < totalelements; ele++) {
                positionElements = position + "." + (ele + 1);

                jsonRes.atributos[i].elements[ele].dato_abierto = jsonRes.atributos[i].dato_abierto;;
                jsonRes.atributos[i].elements[ele].position = positionElements;
                jsonRes.atributos[i].elements[ele].value = HTML_ENTITIES.encode(jsonRes.atributos[i].elements[ele]._value);
                jsonRes.atributos[i].elements[ele].label = HTML_ENTITIES.encode(jsonRes.atributos[i].elements[ele].__text);
                jsonRes.atributos[i].elements[ele].required = jsonRes.atributos[i].elements[ele]._required;
                jsonRes.atributos[i].elements[ele].type = jsonRes.atributos[i].elements[ele]._type;
                jsonRes.atributos[i].elements[ele].name = jsonRes.atributos[i].elements[ele]._name;

                delete jsonRes.atributos[i].elements[ele]._value;
                delete jsonRes.atributos[i].elements[ele].__text;
                delete jsonRes.atributos[i].elements[ele]._required;
                delete jsonRes.atributos[i].elements[ele]._type;
                delete jsonRes.atributos[i].elements[ele]._name;

                if (jsonRes.atributos[i].elements[ele].options != undefined) {
                  var totalsub_options = jsonRes.atributos[i].elements[ele].options.length;
                  //verificamos que tenga el numero de elementos sino lo reseteamos y agregamos los elementos en array
                  if (totalsub_options === undefined) {
                    var array1 = jsonRes.atributos[i].elements[ele].options;
                    var fuente = JSON.stringify(array1);
                    fuente = fuente.replace(/{/g, "[{");
                    fuente = fuente.replace(/}/g, "}]");

                    jsonRes.atributos[i].elements[ele].options = null;
                    jsonRes.atributos[i].elements[ele].options = JSON.parse(fuente);
                    totalsub_options = 1;
                  }
                  for (var sub = 0; sub < totalsub_options; sub++) {
                    positionSubElements = positionElements + "." + (sub + 1);
                    id = "id_" + (positionElements);
                    jsonRes.atributos[i].elements[ele].dato_abierto = jsonRes.atributos[i].dato_abierto;;
                    jsonRes.atributos[i].elements[ele].id = id;
                    jsonRes.atributos[i].elements[ele].position = positionSubElements;
                    jsonRes.atributos[i].elements[ele].options[sub].value = HTML_ENTITIES.encode(jsonRes.atributos[i].elements[ele].options[sub]._value);
                    jsonRes.atributos[i].elements[ele].options[sub].label = HTML_ENTITIES.encode(jsonRes.atributos[i].elements[ele].options[sub].__text);

                    delete jsonRes.atributos[i].elements[ele].options[sub]._value;
                    delete jsonRes.atributos[i].elements[ele].options[sub].__text;
                  }
                }
              }
            }
            // ws lista de webservices anidados
            else if (jsonRes.atributos[i].wsList != undefined) {
              var totalOptions = jsonRes.atributos[i].wsList.length;


              if (totalOptions === undefined) {

                var positionList = 0;
                var array1 = jsonRes.atributos[i].wsList;
                var fuente = JSON.stringify(array1);
                fuente = fuente.replace(/{/g, "[{");

                var resultado = fuente.replace(/}/g, "}]");
                jsonRes.atributos[i].wsList = null;
                jsonRes.atributos[i].wsList = JSON.parse(resultado);

                totalOptions = 1;
              }

              for (var o = 0; o < totalOptions; o++) {
                positionList = position + "." + (o + 1);
                jsonRes.atributos[i].wsList[o].dato_abierto = jsonRes.atributos[i].dato_abierto;;
                jsonRes.atributos[i].wsList[o].position = positionList;
                jsonRes.atributos[i].wsList[o].value = jsonRes.atributos[i].wsList[o]._value;
                jsonRes.atributos[i].wsList[o].id = jsonRes.atributos[i].wsList[o]._id;
                jsonRes.atributos[i].wsList[o].url = jsonRes.atributos[i].wsList[o]._url;
                jsonRes.atributos[i].wsList[o].responseDisplay = jsonRes.atributos[i].wsList[o]._responsedisplay;
                jsonRes.atributos[i].wsList[o].name = jsonRes.atributos[i].wsList[o]._name;
                jsonRes.atributos[i].wsList[o].responseValue = jsonRes.atributos[i].wsList[o]._responsevalue;
                jsonRes.atributos[i].wsList[o].label = HTML_ENTITIES.encode(jsonRes.atributos[i].wsList[o].__text);


                delete jsonRes.atributos[i].wsList[o]._value;
                delete jsonRes.atributos[i].wsList[o]._id;
                delete jsonRes.atributos[i].wsList[o]._url;
                delete jsonRes.atributos[i].wsList[o]._responsevalue;
                delete jsonRes.atributos[i].wsList[o]._responsedisplay;
                delete jsonRes.atributos[i].wsList[o]._name;
                delete jsonRes.atributos[i].wsList[o].__text;

              }

            }
            //selects Radios y checkc
            else if (jsonRes.atributos[i].option != undefined) {
              var totalOptions = jsonRes.atributos[i].option.length;

              for (var ws = 0; ws < totalOptions; ws++) {
                jsonRes.atributos[i].option[ws].dato_abierto = jsonRes.atributos[i].dato_abierto;
                jsonRes.atributos[i].option[ws].value = HTML_ENTITIES.encode(jsonRes.atributos[i].option[ws]._value);
                jsonRes.atributos[i].option[ws].label = HTML_ENTITIES.encode(jsonRes.atributos[i].option[ws].__text);

                delete jsonRes.atributos[i].option[ws]._value;
                delete jsonRes.atributos[i].option[ws].__text;
              }
            }
          }
        }


        var numElements = 0;
        var esEstepper = null;
        var stepper_num = null;
        var estepperNoAlign = 0;
        Ember.$.each(jsonRes.atributos, function (i) {
          numElements = i;
          // console.log("type ---> "+jsonRes.atributos[i].type);

          if (jsonRes.atributos[i].type === 'header') {
            if (jsonRes.atributos[i].element === 'Stepper') {
              esEstepper = i;
              stepper_num = stepper_num + 1;
            }

          }

          if (esEstepper > 0 && numElements > 0 && stepper_num == 1) {
            estepperNoAlign = 1;

          }
        });

        if (estepperNoAlign == 1) {
          _helpers.dialog2('Tienes elementos arriba de la primera sección, desciéndelos para continuar.');
          console.log('Tienes elementos arriba de la primera sección, desciéndelos para continuar.');
        }

        res = JSON.stringify(jsonRes);


        // console.log(" res----> " + res);
        localStorage.setItem('JsonForm', res);

      } else {
        localStorage.setItem('JsonForm', "");
      }

    });


    // Clear all fields in form editor
    var clearButton = $(document.getElementById(frmbID + '-clear-all'));
    clearButton.click(function () {
      var fields = $('li.form-field');
      var buttonPosition = this.getBoundingClientRect(),
        bodyRect = document.body.getBoundingClientRect(),
        coords = {
          pageX: buttonPosition.left + buttonPosition.width / 2,
          pageY: buttonPosition.top - bodyRect.top - 12
        };

      if (fields.length) {
        _helpers.confirm(opts.messages.clearAllMessage, function () {
          _helpers.removeAllfields();
          opts.notify.success(opts.messages.allFieldsRemoved);
          _helpers.save();
        });
      } else {
        _helpers.dialog('No Existen campos, el escritorio esta vacío');
      }
    });

    // Save Idea Template
    $(document.getElementById(frmbID + '-save')).click(function (e) {
      e.preventDefault();
      _helpers.save();
      _helpers.validateForm(e);
    });

    elem.parent().find('p[id*="ideaTemplate"]').remove();
    elem.wrap('<div class="template-textarea-wrap"/>');

    loadData();

    $sortableFields.css('min-height', $cbUL.height());

    document.dispatchEvent(formBuilder.events.loaded);

    return formBuilder;
  };

  $.fn.formBuilder = function (options) {
    return this.each(function () {
      var element = this,
        formBuilder;
      if ($(element).data('formBuilder')) {
        var existingFormBuilder = $(element).parents('.form-builder:eq(0)');
        var newElement = $(element).clone();
        existingFormBuilder.before(newElement);
        existingFormBuilder.remove();
        formBuilder = new FormBuilder(options, newElement[0]);
        newElement.data('formBuilder', formBuilder);
      } else {
        formBuilder = new FormBuilder(options, element);
        $(element).data('formBuilder', formBuilder);
      }
    });
  };
})(jQuery);
'use strict';

// toXML is a jQuery plugin that turns our form editor into XML
// @todo this is a total mess that has to be refactored
(function ($) {
  'use strict';

  $.fn.toXML = function (_helpers) {

    var serialStr = '';
    var fieldOptions = function fieldOptions($field) {
      var types = _helpers.getTypes($field);
      var flag = 0;
      //******
      if (types.type === "elementsGroup") {

        var required = $('input.required', $field).is(':checked');

        var numberEleCtrl = localStorage.getItem('ctrlElements');
        var numberEleInitial = localStorage.getItem('initialElements');

        if (numberEleCtrl !== numberEleInitial) {
          _helpers.dialog2('El grupo de elementos debe contar con 2 o más elementos, por favor agr&eacute;guelos para continuar.');
          localStorage.setItem('ctrlElements', numberEleInitial);
        } else {

          localStorage.setItem('ctrlElements', numberEleInitial);

        }

        var options = [];
        var consec = 1;

        var numberElements = $('ol li', $field).length;
        if (numberElements < 2) {
          flag = flag + 1;
        }
        if (flag == 1) {

        }

        $('ol li', $field).each(function (indice, elemento) {
          var $option_values = $(this);
          var option_value = _helpers.escapeAlphanumerics($(".option-value", $option_values).val());
          var option_label = $(".option-label", $option_values).val();
          var option_type = $(".option-type", $option_values).val();
          var field = '';


          option_value = option_value !== "" ? option_value : "Value";
          option_label = option_label !== "" ? option_label : "Label";
          option_type = option_type !== "" ? option_type : "error";

          if (option_type.trim() === "text" || option_type.trim() === "textarea") {

            field += '<elements required = ' + required + ' type = "' + option_type.trim() + '"  name= "' + HTML_ENTITIES.encode(option_type.trim().replace(/\s/g, "_")) + consec + '"  value= "' + HTML_ENTITIES.encode(option_value.trim().replace(/\s/g, "_")) + '"  _text= "' + HTML_ENTITIES.encode(option_label.trim()) + '">';
            field += '</elements>';
          } else {
            field += '<elements required = ' + required + ' type = "' + option_type.trim() + '"  name= "' + HTML_ENTITIES.encode(option_type.trim().replace(/\s/g, "_")) + consec + '"  value= "' + HTML_ENTITIES.encode(option_value.trim().replace(/\s/g, "_")) + '">';
            field += HTML_ENTITIES.encode(option_label);
            var sub_field = "";

            //Values de elemntos select y check
            $option_values.find('ul dl').each(function (indice, elemento) {

              var $option_subvalues = $(this);
              var option_value_sub = _helpers.escapeAlphanumerics($(".option-value", $option_subvalues).val());
              var option_label_sub = $(".option-label", $option_subvalues).val();

              option_value_sub = option_value_sub !== "" ? option_value_sub : "Value";
              option_label_sub = option_label_sub !== "" ? option_label_sub : "Label";
              sub_field += '<options value= "' + HTML_ENTITIES.encode(option_value_sub.replace(/\s/g, "_")) + '">';
              sub_field += HTML_ENTITIES.encode(option_label_sub);
              sub_field += '</options>';
            });

            field += sub_field;
            field += '</elements>';
          }
          options.push('\n\t\t\t' + field);
          consec++;

        });


        return options.join('') + '\n\t\t';
      }
      // Elementos anidados  recorre los campos y los asigna al xm  que se va a convertir en json
      else if (types.type == "selectnested") {
        var options = [];
        var consec = 1;
        var lSelect = _helpers.getSelectLabel($field);
        console.log('size ??? => ', $('ol li', $field))
        var numberElements = $('ol li', $field).length;
        $('ol li', $field).each(function () {
          var $optionNested = $(this);
          var option_value = _helpers.escapeAlphanumerics($(".option-value", $optionNested).val());
          var option_label = $(".option-label", $optionNested).val();
          var field = '';


          // Evaluamos que traigan un valor difente a vacio  si no es asi se le asigna un valor por default;
          option_value = option_value !== "" ? option_value : "Value";
          option_label = option_label !== "" ? option_label : "Label";

          field += '<options label_Principal = "' + lSelect.etiqueta2 + '"  value= "' + HTML_ENTITIES.encode(option_value.trim().replace(/\s/g, "_")) + '">';
          field += HTML_ENTITIES.encode(option_label);

          var sub_field = "";
          //Values de elemntos select y check
          $optionNested.find('ul dl').each(function (indice, elemento) {

            var $option_subvalues = $(this);
            var option_value_sub = _helpers.escapeAlphanumerics($(".option-value", $option_subvalues).val());
            var option_label_sub = $(".option-label", $option_subvalues).val();

            option_value_sub = option_value_sub !== "" ? option_value_sub : "Value";
            option_label_sub = option_label_sub !== "" ? option_label_sub : "Label";
            sub_field += '<sub_options value= "' + HTML_ENTITIES.encode(option_value_sub.replace(/\s/g, "_")) + '">';
            sub_field += HTML_ENTITIES.encode(option_label_sub);
            sub_field += '</sub_options>';
          });
          field += sub_field;
          field += '</options>';
          options.push('\n\t\t\t' + field);
          consec++;
        });

        return options.join('') + '\n\t\t';
      }
      else if (types.type == "selectWSnested") {
        var options = [];
        var consec = 1;

        var numberElements = $('ol li', $field).length;
        $('ol li', $field).each(function (indice) {
          var $optionNested = $(this);
          var option_value = _helpers.escapeAlphanumerics($(".option-value", $optionNested).val());
          var option_label = $(".option-label", $optionNested).val();
          var ws_selected = $(".fld-wslist option:selected", $optionNested).val();
          var is_class = $(".fld-clase", $optionNested).val();
          var field = '';
          // Evaluamos que traigan un valor difente a vacio  si no es asi se le asigna un valor por default;
          option_value = option_value !== "" ? option_value : "Value";
          option_label = option_label !== "" ? option_label : "Label";
          ws_selected = ws_selected !== "" ? ws_selected : "WS";

          // Vamos a traer la wsdl
          /*     wsname: wslist.ws_name,
                 ws_jerarquia: wslist.ws_jerarquia,
                 ws_id: wslist.ws_id,
                 response_display: wslist.ws_response_display,
                 response_value: wslist.ws_response_value,

                 */
          var ws_i = _helpers.getUrlWs(ws_selected);
          field += '<wsList  name= "' + ws_i.ws_name + '" responseValue= "' + ws_i.ws_response_value + '" responseDisplay= "' + ws_i.ws_response_display + '" id = "ws_' + (indice + 1) + '" url = "' + ws_i.ws_list + '" value= "' + HTML_ENTITIES.encode(option_value.trim().replace(/\s/g, "_")) + '">';
          field += HTML_ENTITIES.encode(option_label);

          var sub_field = "";
          //Values de elemntos select y check
          $optionNested.find('ul dl').each(function (indice, elemento) {

            var $option_subvalues = $(this);
            var option_value_sub = _helpers.escapeAlphanumerics($(".option-value", $option_subvalues).val());
            var option_label_sub = $(".option-label", $option_subvalues).val();

            option_value_sub = option_value_sub !== "" ? option_value_sub : "Value";
            option_label_sub = option_label_sub !== "" ? option_label_sub : "Label";
            sub_field += '<sub_options value= "' + HTML_ENTITIES.encode(option_value_sub.replace(/\s/g, "_")) + '">';
            sub_field += HTML_ENTITIES.encode(option_label_sub);
            sub_field += '</sub_options>';
          });
          field += sub_field;
          field += '</wsList>';
          options.push('\n\t\t\t' + field);
          consec++;
        });

        return options.join('') + '\n\t\t';
      }
      else {
        var options = [];
        $('.sortable-options li ', $field).each(function () {
          var $option = $(this);
          var label = HTML_ENTITIES.encode($('.option-label', $option).val());
          var value = _helpers.escapeAlphanumerics($('.option-value', $option).val());


          var attrs = {
            value: value !== "" ? value : 'Value',

            selected: $('.option-selected', $option).is(':checked')
          },
            option = _helpers.markup('option', label !== "" ? label : 'Label', attrs).outerHTML;

          options.push('\n\t\t\t' + option);
        });
        return options.join('') + '\n\t\t';

      }

    };

    // Begin the core plugin
    this.each(function () {
      var numberStepper = null;
      var sortableFields = this;
      if (sortableFields.childNodes.length >= 1) {
        serialStr += '<form-template>\n\t<fields>';
        // build new xml
        _helpers.forEach(sortableFields.childNodes, function (index, field) {
          index = index;
          var $field = $(field);
          var fieldData = $field.data('fieldData');
          //alert(JSON.stringify(fieldData));
          if (!$field.hasClass('disabled')) {
            var roleVals = $('.roles-field:checked', field).map(function () {
              return this.value;
            }).get();
            var enableOther = $('[name="enable-other"]:checked', field).length;
            var types = _helpers.getTypes($field);

            var startDate = "";

            var finalDate = "";
            var textoApoyo = "";
            var elementType = "";
            if (types.type == "date") {
              startDate = _helpers.getStartdate($field);
              finalDate = _helpers.getFinaldate($field);
            }
            textoApoyo = _helpers.getTextoApoyo($field);

            var regex = _helpers.getRegex($field);

            if (types.type === "header") {
              elementType = _helpers.getElementType($field);
              // validando que solo  elemento  etiqueta contenga  h1 h2 h3 h4 h5 h6
              if (elementType.elementType === "Label") {
                //poner tipo de etiqueta
                $('.subtype-wrap', $field).show("slow");
              } else {
                $('.subtype-wrap', $field).hide("slow");
                // quitar
              }
            }

            if (types.type === "header") {
              if (elementType.elementType === "Stepper") {
                numberStepper++;
              }
            }
            var regex64 = _helpers.getRegexParse(regex.regex_type);
            var urlButton = _helpers.getWsUrlResult($field);
            // var urlButton = _helpers.getUrlButton($field);
            // var numberStepper = _helpers.getNumberStepper($field);
            // var numberGroup = _helpers.getNumberGroup($field);
            var redirect = _helpers.getRedirect($field);
            var nseleccionados = _helpers.getnSeleccionados($field);

            var importancia = _helpers.getImportancia($field);
            var wslist = _helpers.getWslist($field);
            // var responseDisplay = _helpers.getResponseDisplay($field);
            //  var responseValue = _helpers.getResponseValue($field);
            var etiqueta2 = _helpers.getSelectLabel($field);
            var datosAbiertos = _helpers.getDatosAbiertos($field);
            var max = $('input.fld-maxlength', $field).val();
            var des = $('input.fld-description', $field).val();
            var req = $('input.required', $field).is(':checked').toString();
            var class_ = fieldData.className;
            var n = fieldData.className.search("form-group");
            if (n <= 0) {
              class_ = class_ + " form-group col-md-7 "
            }
            var con = false;
            var label = "";

            label = $('.fld-label', $field).val()
            var xmlAttrs = {
              className: class_,
              description: des,
              label: label,
              maxlength: max,
              multiple: $('input[name="multiple"]', $field).is(':checked'),
              name: $('input.fld-name', $field).val(),
              placeholder: $('input.fld-placeholder', $field).val(),
              required: req,
              toggle: $('.checkbox-toggle', $field).is(':checked'),
              type: types.type,
              subtype: types.subtype,
              element: elementType.elementType,
              regex: regex64.regex_64,
              regex_msg: regex64.regex_msg,
              regextype: regex.regex_type,
              startdate: startDate.startdate,
              finaldate: finalDate.finaldate,
              nseleccionados: nseleccionados.nseleccionados,
              textoapoyo: textoApoyo.textoapoyo,
              importancia: importancia.importancia,
              url_button: urlButton.wsUrl,
              number_stepper: numberStepper,
              redirect: redirect.redirect,
              dato_abierto: datosAbiertos.datosAbiertos,
              etiqueta2: etiqueta2.etiqueta2


            };
            if (roleVals.length) {
              xmlAttrs.role = roleVals.join(',');
            }
            if (enableOther) {
              xmlAttrs.enableOther = 'true';
            }
            xmlAttrs = _helpers.trimAttrs(xmlAttrs);
            xmlAttrs = _helpers.escapeAttrs(xmlAttrs);
            var multipleField = xmlAttrs.type.match(/(elementsGroup|select|selectws|selectnested|checkbox-group|radio-group)/);

            var fieldContent = '',
              xmlField;
            if (multipleField) {
              fieldContent = fieldOptions($field);
            }

            xmlField = _helpers.markup('field', fieldContent, xmlAttrs);
            xmlField.getElementsByTagName("field");
            serialStr += '\n\t\t' + xmlField.outerHTML;
          }
        });
        serialStr += '\n\t</fields>\n</form-template>';
      } // if "$(this).children().length >= 1"

    });
    // console.log(serialStr);

    return serialStr;
  };
})(jQuery);
'use strict';

// Polyfill for Object.assign

if (typeof Object.assign !== 'function') {
  (function () {
    Object.assign = function (target) {
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var output = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source !== undefined && source !== null) {
          for (var nextKey in source) {
            if (source.hasOwnProperty(nextKey)) {
              output[nextKey] = source[nextKey];
            }
          }
        }
      }
      return output;
    };
  })();
}

// Element.remove() polyfill
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

// Event polyfill
if (typeof Event !== 'function') {
  (function () {
    window.Event = function (evt) {
      var event = document.createEvent('Event');
      event.initEvent(evt, true, true);
      return event;
    };
  })();
}

function ClassDisabled(elementType) {
  console.log('tipo de elemento --->' + elementType);
  var classDisabled = '';
  if (parseInt(localStorage.getItem('statusForm')) === 3 && elementType !== 'label') {
    // && && elementType !== 'button' && elementType !== 'br'
    classDisabled = 'disabled';
  }


  return classDisabled;
}
function ClassDisplayField() {
  return 'displaynone';
}
